import baseTheme from '../base'

const MuiMenu = {
  defaultProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  },
  styleOverrides: {
    paper: {
      '&.MuiPaper-root': {
        borderRadius: baseTheme.spacing(1.25),
        borderTopRightRadius: 0,
        backgroundColor: baseTheme.palette.neutral[300],
        boxShadow: baseTheme.shadows[4],
        overflow: 'visible',
        // NOTE: Offset added to make the header shadow visible above the menu
        marginTop: 1,
      },
    },
    list: {
      paddingTop: baseTheme.spacing(1),
      paddingBottom: baseTheme.spacing(1),
    },
  },
}

export default MuiMenu
