import jwtDecode from 'jwt-decode'

import AuthServiceCognito from './AuthServiceCognito'
import { getCognitoSetup } from '../environment'

const CognitoSetup = getCognitoSetup()

const getTokens = async (code) => {
  const response = await fetch(`${CognitoSetup.domain}/oauth2/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: CognitoSetup.clientId,
      code,
      redirect_uri: window.location.origin,
    }).toString(),
  })

  return await response.json()
}

export const redirectToLogin = () => {
  const searchParams = new URLSearchParams({
    client_id: CognitoSetup.clientId,
    response_type: 'code',
    redirect_uri: window.location.origin,
  })

  const origin = `${CognitoSetup.domain}/login`
  /*
    NOTE: scope is not appended to searchParams to prevent its value from being
    encoded, what makes cognito fail
  */
  window.location = `${origin}?${searchParams.toString()}&scope=email+openid+profile`
}

export const logout = async () => {
  const refreshToken = window.localStorage.getItem('refresh_token')

  const response = await fetch(`${CognitoSetup.domain}/oauth2/revoke`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      client_id: CognitoSetup.clientId,
      token: refreshToken,
    }),
  })

  if (response.ok) {
    window.localStorage.removeItem('id_token')
    window.localStorage.removeItem('access_token')
    window.localStorage.removeItem('refresh_token')
    window.localStorage.removeItem('token_type')
    window.localStorage.removeItem('expires_in')
    window.location.reload()
    return
  }

  const data = await response.json()
  return Promise.reject(data)
}

const storeTokens = (tokens) => {
  Object.entries(tokens).forEach(([key, value]) => {
    window.localStorage.setItem(key, value)
  })
}

export const getAccessToken = () => window.localStorage.getItem('access_token')
export const getIdToken = () => window.localStorage.getItem('id_token')

export const isAuthenticated = () => {
  const token = window.localStorage.getItem('access_token')
  return token !== null
}

export const getTokensAndReload = async (code) => {
  const tokens = await getTokens(code)
  storeTokens(tokens)

  window.location = window.location.origin
}

export const getUsername = () => {
  if (!isAuthenticated()) return ''

  const idToken = window.localStorage.getItem('id_token')
  const decoded = jwtDecode(idToken)
  return decoded.name
}

const getEmail = () => {
  const idToken = window.localStorage.getItem('id_token')
  const decoded = jwtDecode(idToken)
  return decoded.email
}

const authServiceCognito = new AuthServiceCognito()

export const changePassword = async (oldPassword, newPassword) => {
  const email = getEmail()
  await authServiceCognito.changePassword(email, oldPassword, newPassword)
}
