import baseTheme from '../base'

const MuiMenuItem = {
  styleOverrides: {
    root: {
      columnGap: baseTheme.spacing(1),
      padding: baseTheme.spacing(0.5, 1.25),
      background: baseTheme.palette.neutral[300],

      [baseTheme.breakpoints.up('md')]: {
        padding: baseTheme.spacing(0.75, 2),
      },

      '&:hover, &:focus-visible': {
        background: baseTheme.palette.neutral[200],
        zIndex: 1,
      },

      '& > .MuiListItemIcon-root': {
        minWidth: baseTheme.spacing(1),
        color: baseTheme.palette.neutral[500],
      },
    },
  },
}

export default MuiMenuItem
