const styles = {
  sectionLayout: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: { xs: 4, lg: 5 },
    pb: 4,
  },
  emptyStateContainer: {
    backgroundColor: 'neutral.200',
    flex: 1,
  },
}

export default styles
