import { getAccessToken } from './authentication'

const BACKOFFICE_ENDPOINT = process.env.REACT_APP_BACKOFFICE_ENDPOINT

export const getKPIsChartData = async (mediaProviderIds) => {
  const searchParams = new URLSearchParams({})
  for (const id of mediaProviderIds) {
    searchParams.append('mediaId', id)
  }

  const response = await fetch(
    `${BACKOFFICE_ENDPOINT}/media-provider-admin/kpis-chart?${searchParams.toString()}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  )

  if (response.ok) {
    return await response.json()
  } else {
    throw new Error(await response.json().then((r) => r.error))
  }
}
