export const PURCHASE_TABLE_HEAD_DATA = [
  {
    field: 'date',
    label: 'Fecha',
  },
  {
    field: 'headline',
    label: 'Noticia',
  },
  {
    field: 'userName',
    label: 'Usuario',
  },
  {
    field: 'price',
    label: 'Importe',
  },
]

const INITIAL_ROWS_PER_PAGE = 10
const INITIAL_PAGE = 0
export const PURCHASE_TABLE_ROWS_PER_PAGE_OPTIONS = [5, 10, 20]

export const DEFAULT_PAGINATION = {
  limit: INITIAL_ROWS_PER_PAGE,
  offset: INITIAL_PAGE,
  orderBy: 'date', // one of: 'price' | 'headline' | 'userFullName' | 'date'
  orderDirection: 'DESC', // one of: 'ASC' | 'DESC'
}
