const MuiDialog = {
  defaultProps: {
    fullWidth: true,
  },
  styleOverrides: {
    paper: {
      padding: 0,
      borderRadius: 0,
      border: 0,
    },
    paperFullWidth: {
      width: '100vw',
      height: '100%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      margin: 0,
    },
  },
}

export default MuiDialog
