import baseTheme from '../base'

const MuiButtonBase = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      '&:focus-visible': {
        outline: `max(2px, 0.08em) solid ${baseTheme.palette.primary.main}`,
      },
    },
  },
}

export default MuiButtonBase
