import writeXlsxFile from 'write-excel-file'
import { saveAs } from 'file-saver'

const exportExcel = async (data, schema, fileName = 'payper-excel') => {
  const excelFile = await writeXlsxFile(data, {
    schema,
    headerStyle: {
      backgroundColor: '#eeeeee',
      fontWeight: 'bold',
      align: 'center',
    },
    fileName: fileName,
    stickyRowsCount: 1,
  })

  saveAs(excelFile, fileName)
}

const PURCHASES_TABLE_SCHEMA = [
  { column: 'Fecha', value: (data) => data.date, width: 16 },
  { column: 'Noticia', value: (data) => data.headline, width: 75 },
  { column: 'Usuario', value: (data) => data.userFullName, width: 16 },
  {
    column: 'Importe',
    value: (data) => `${data.price} €`,
    width: 16,
  },
]
const purchasesFileName = 'Payper_Registro_de_compras'
const splitStrDate = (date) =>
  `${date.slice(0, 4)}_${date.slice(4, 6)}_${date.slice(6)}`

const getPurchasesFileName = (filters) => {
  const currentDate = new Intl.DateTimeFormat('default').format(new Date())
  let fileName = `${currentDate}_${purchasesFileName}`
  if (Boolean(filters?.startDate)) {
    fileName = fileName.concat(`_Desde:${splitStrDate(filters.startDate)}`)
  }
  if (Boolean(filters?.endDate)) {
    fileName = fileName.concat(`_Hasta:${splitStrDate(filters.endDate)}`)
  }
  return fileName
}

export const exportPurchasesTableExcel = async (data, filters) =>
  await exportExcel(data, PURCHASES_TABLE_SCHEMA, getPurchasesFileName(filters))
