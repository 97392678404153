import { Box, Container } from '@mui/material'

import { CircularProgress, EmptyState } from '../../components'
import { useUserContext } from '../../UserContext'
import { useTexts } from '../../../texts'
import KPIs from './KPIs'
import PurchasesTable from './PurchasesTable'
import MediaProviderMultiSelector from './MediaProviderMultiSelector'
import styles from './home.styles'
import useMediaProviderMultiSelector from './MediaProviderMultiSelector/useMediaProviderMultiSelector'

const Home = () => {
  const { user } = useUserContext()

  if (!Boolean(user?.mediaProviders)) return <CircularProgress />

  return <HomePage mediaProviders={user?.mediaProviders} />
}

const HomePage = ({ mediaProviders }) => {
  const texts = useTexts()
  const selectorProps = useMediaProviderMultiSelector(mediaProviders.data)

  return (
    <>
      <MediaProviderMultiSelector selectorProps={selectorProps} />
      {selectorProps.selected.length !== 0 ? (
        <>
          <Box sx={styles.sectionLayout}>
            <KPIs mediaProviderIds={selectorProps.selected} />
            <PurchasesTable mediaProviderIds={selectorProps.selected} />
          </Box>
        </>
      ) : (
        <Box sx={styles.emptyStateContainer}>
          <Container>
            <EmptyState
              sx={styles.light}
              title={texts.getNoMediaEmptyState()}
            />
          </Container>
        </Box>
      )}
    </>
  )
}

export default Home
