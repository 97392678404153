import baseTheme from '../base'

const MuiInputAdornment = {
  defaultProps: {
    position: 'start',
  },
  styleOverrides: {
    root: {
      fontSize: baseTheme.spacing(1.25),
      color: 'currentColor',

      // NOTE: Increase specificity for better placing the icon
      '&.MuiInputAdornment-filled.MuiInputAdornment-sizeMedium:not(.MuiInputAdornment-hiddenLabel)':
        {
          marginTop: 0,
        },
    },
    positionEnd: {
      '& .MuiButtonBase-root': {
        color: baseTheme.palette.neutral[400],
      },
    },
  },
}

export default MuiInputAdornment
