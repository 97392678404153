import PropTypes from 'prop-types'
import { Box, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { ReactComponent as CaretLeft } from './CaretLeft.svg'
import { ReactComponent as CaretRight } from './CaretRight.svg'
import styles from './breadcrumbs.styles'

const breadcrumbsPropTypes = {
  config: PropTypes.shape({
    base: PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }).isRequired,
    current: PropTypes.shape({
      label: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

const Breadcrumbs = ({ config }) => {
  const theme = useTheme()
  const widerThanTablet = useMediaQuery(theme.breakpoints.up('md'))

  const createBasePath = () =>
    widerThanTablet ? (
      <>
        <Box component='li' sx={{ ...styles.item, ...styles.active }}>
          <Button sx={styles.link} onClick={config.base.onClick}>
            {config.base.label}
          </Button>
        </Box>
        <Box component='li' sx={styles.decorator} aria-hidden='true'>
          <CaretRight />
        </Box>
      </>
    ) : (
      <Box component='li' sx={styles.icon}>
        <Button sx={styles.iconLink} onClick={config.base.onClick}>
          <CaretLeft />
        </Button>
      </Box>
    )

  return (
    <Box sx={styles.root}>
      <Box component='nav' sx={styles.content}>
        <Box component='ol' sx={styles.list}>
          {createBasePath()}
          <Box component='li' sx={styles.item}>
            {config.current.label}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

Breadcrumbs.propTypes = breadcrumbsPropTypes

export default Breadcrumbs
