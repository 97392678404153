import { logout } from '../../../services'
import { useUserContext } from '../../UserContext'
import View from './View'

const Header = ({ goToConfig }) => {
  const { user } = useUserContext()
  const userMenuLabel = user?.name ?? user?.email ?? ''

  return <View name={userMenuLabel} logout={logout} goToConfig={goToConfig} />
}

export default Header
