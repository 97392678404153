import { useState, useMemo } from 'react'

import Authentication from './Authentication'
import { UserProvider } from './UserContext'
import Layout from './Layout'
import { Home, Configuration } from './Pages'
import { Header, Breadcrumbs } from './components'
import { useTexts } from '../texts'

const PAGES = {
  HOME: Symbol('HOME'),
  CONFIGURATION: Symbol('CONFIGURATION'),
}

const App = () => {
  const texts = useTexts()

  const [page, setPage] = useState(PAGES.HOME)

  const goToConfig = () => setPage(PAGES.CONFIGURATION)

  const breadcrumbsConfig = useMemo(
    () => ({
      base: {
        label: texts.getHomeTitle(),
        onClick: () => setPage(PAGES.HOME),
      },
      current: {
        label: texts.getConfigurationTitle(),
      },
    }),
    // eslint-disable-next-line
    [],
  )

  return (
    <Authentication>
      <UserProvider>
        <>
          <Header goToConfig={goToConfig} />
          {page === PAGES.HOME ? (
            <Layout>
              <Home />
            </Layout>
          ) : page === PAGES.CONFIGURATION ? (
            <>
              <Layout>
                <Breadcrumbs config={breadcrumbsConfig} />
                <Layout>
                  <Configuration />
                </Layout>
              </Layout>
            </>
          ) : null}
        </>
      </UserProvider>
    </Authentication>
  )
}

export default App
