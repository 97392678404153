import { useState, useEffect } from 'react'

import { getWeeklyIncome, getMonthlyIncome } from '../../../../services'
import { formatPriceCardContent } from './formatCardContent'
import { useTexts } from '../../../../texts'
import { CardGroup, Card } from '../../../components'
import { ReactComponent as Coins } from './Coins.svg'

const IncomeCardGroup = ({ mediaProviderIds }) => {
  const texts = useTexts()

  const [weeklyIncome, setWeeklyIncome] = useState()
  const [monthlyIncome, setMonthlyIncome] = useState()

  useEffect(() => {
    ;(async () => {
      if (Boolean(mediaProviderIds)) {
        setWeeklyIncome(await getWeeklyIncome(mediaProviderIds))
        setMonthlyIncome(await getMonthlyIncome(mediaProviderIds))
      }
    })()
  }, [mediaProviderIds])

  return (
    <CardGroup title={texts.getIncomeTitle()} icon={<Coins />}>
      {weeklyIncome ? (
        <Card
          title={texts.getCardWeeklyTitle()}
          balanceLabel={texts.getCardLastWeekLabel()}
          {...formatPriceCardContent(weeklyIncome)}
        />
      ) : null}
      {monthlyIncome ? (
        <Card
          title={texts.getCardMonthlyTitle()}
          balanceLabel={texts.getCardLastMonthLabel()}
          {...formatPriceCardContent(monthlyIncome)}
        />
      ) : null}
    </CardGroup>
  )
}

export default IncomeCardGroup
