import { changePassword as changePasswordService } from '../../../services'
import { useForm, composeValidators, isFilled, isValidPassword } from './forms'
import { PasswordForm } from '../../components'
import { useTexts } from '../../../texts'

const PasswordChange = () => {
  const texts = useTexts()

  const changePassword = async ({ oldPassword, newPassword }) => {
    try {
      await changePasswordService(oldPassword, newPassword)
    } catch (e) {
      if (e.name === 'NotAuthorizedException') {
        throw new Error(texts.getOldPasswordError())
      }
      throw new Error(texts.getUnexpectedError())
    }
  }

  const FORM_VALIDATION_CONFIG = {
    oldPassword: composeValidators([[isFilled, texts.getErrorRequired()]]),
    newPassword: composeValidators([
      [isFilled, texts.getErrorRequired()],
      [isValidPassword, texts.getErrorPassword()],
    ]),
  }

  const { status, submitForm, isSubmitSuccess } = useForm(
    changePassword,
    FORM_VALIDATION_CONFIG,
  )

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    const formData = new FormData(ev.target)
    const formValues = Object.fromEntries(formData)
    await submitForm(formValues)
  }

  return (
    <PasswordForm
      {...status}
      onSubmit={handleSubmit}
      showSuccess={isSubmitSuccess}
    />
  )
}

export default PasswordChange
