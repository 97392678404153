const styles = {
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 1,
    pt: 1,
    px: 1.5,
    pb: 2,
    borderRadius: (theme) => theme.spacing(1.25),
    boxShadow: 2,
    backgroundColor: 'neutral.100',
  },
  title: {
    alignSelf: 'flex-start',
    color: 'neutral.700',
    fontSize: (theme) => theme.spacing(0.875),
    fontWeight: 600,
    lineHeight: 1.5,
  },
  info: {
    textAlign: 'center',
  },
  amount: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    columnGap: 0.25,
    mb: 0.5,
    color: 'neutral.600',
    fontFamily: 'ClashDisplay-Semibold',
    fontSize: (theme) => ({
      xs: theme.spacing(2),
      md: theme.spacing(2),
    }),
    fontWeight: 600,
    lineHeight: 1.1,
    letterSpacing: '0.02em',

    '& > span': {
      fontFamily: 'Inter',
      fontSize: (theme) => theme.spacing(1.25),
      fontWeight: 500,
      color: 'neutral.400',
    },
  },
  balance: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 0.25,
    mb: 0.125,
    fontSize: (theme) => theme.spacing(0.875),
    fontWeight: 500,
    lineHeight: 1.5,
    color: 'var(--text-color)',

    '& > strong': {
      fontWeight: 700,
    },
  },
  positive: {
    '--text-color': (theme) => theme.palette.success.dark,
    '--icon-color': (theme) => theme.palette.success.main,
  },
  negative: {
    '--text-color': (theme) => theme.palette.error.dark,
    '--icon-color': (theme) => theme.palette.error.main,
  },
  wrapIcon: {
    display: 'flex',
    fontSize: 'inherit',
    color: 'var(--icon-color)',
  },
  date: {
    fontSize: (theme) => theme.spacing(0.75),
    fontWeight: 500,
    lineHeight: 1.5,
    color: 'neutral.400',
  },
}

export default styles
