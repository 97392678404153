import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

import { ReactComponent as Warning } from './Warning.svg'
import { ReactComponent as WarningCircle } from './WarningCircle.svg'
import styles from './alert.styles'

const alertPropTypes = {
  variant: PropTypes.oneOf(['info', 'error', 'success', 'warning']).isRequired,
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  sx: PropTypes.object,
}

const Alert = ({ variant, title, text, sx }) => {
  return (
    <Box sx={{ ...styles.root, ...styles[variant], ...sx }}>
      <Box sx={styles.icon}>
        {variant === 'info' || variant === 'success' ? (
          <WarningCircle />
        ) : (
          <Warning />
        )}
      </Box>
      <Box sx={styles.content}>
        {title ? <Typography sx={styles.title}>{title}</Typography> : null}
        <Typography sx={styles.text}>{text}</Typography>
      </Box>
    </Box>
  )
}

Alert.defaultProps = {
  variant: 'info',
}

Alert.propTypes = alertPropTypes

export default Alert
