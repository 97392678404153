import { Box } from '@mui/material'

import Heading from '../Heading'
import styles from './cardGroup.styles'

const CardGroup = ({ title, icon, children }) => {
  return (
    <Box key={title} sx={styles.group}>
      <Heading Icon={icon} text={title} />
      <Box sx={styles.container}>{children}</Box>
    </Box>
  )
}

export default CardGroup
