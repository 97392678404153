import {
  isAuthenticated,
  redirectToLogin,
  getTokensAndReload,
} from '../services'

const Authentication = ({ children }) => {
  if (isAuthenticated()) {
    return children
  }

  const searchParams = new URLSearchParams(window.location.search)

  if (!searchParams.has('code')) {
    redirectToLogin()
    return <div>Loading...</div>
  }

  const code = searchParams.get('code')
  getTokensAndReload(code)

  return <div>Loading...</div>
}

export default Authentication
