const focusStyles = {
  outline: (theme) => `max(2px, 0.08em) solid ${theme.palette.primary.main}`,
  borderRadius: (theme) => theme.spacing(0.125),
}

const styles = {
  dark: {
    '--default--fill': (theme) => theme.palette.neutral[400],
    '--active--fill': (theme) => theme.palette.neutral[200],
    '--active-check--stroke': (theme) => theme.palette.neutral[600],
    color: 'neutral.300',

    '& b': {
      fontWeight: 'inherit',
      color: 'info.main',
    },

    '&:hover:not(.Mui-error)': {
      '--default--stroke': (theme) => theme.palette.neutral[100],

      '& svg': {
        filter: (theme) =>
          `drop-shadow(0px 1px 0px ${theme.palette.neutral[500]})`,
      },
    },

    '& input:focus-visible + svg': { ...focusStyles },

    '&.Mui-error': {
      '--default--fill': (theme) => theme.palette.error.light,
      color: 'error.light',
    },
  },
  light: {
    '--default--fill': (theme) => theme.palette.neutral[300],
    '--active--fill': (theme) => theme.palette.neutral[700],
    '--active-check--stroke': (theme) => theme.palette.neutral[100],
    color: (theme) => theme.palette.neutral[500],

    '&:hover:not(.Mui-error)': {
      '--default--stroke': (theme) => theme.palette.neutral[400],

      '& svg': {
        filter: (theme) =>
          `drop-shadow(0px 1px 0px ${theme.palette.neutral[300]})`,
      },
    },

    '& input:focus-visible + svg': { ...focusStyles },

    '&.Mui-error': {
      '--default--fill': (theme) => theme.palette.error.light,
      color: 'error.darkest',
    },
  },
  small: {
    '& > .MuiTypography-root': {
      fontSize: (theme) => theme.spacing(0.75),
    },
  },
  medium: {},
}

export default styles
