import { startOfMonth } from 'date-fns'

import { formatDateParam } from '../App/format'

export const getMonthPeriodForDate = (date) => ({
  startDate: startOfMonth(date),
  endDate: date,
})

export const getSearchParamsFromPeriod = (period) =>
  new URLSearchParams({
    startDate: formatDateParam(period.startDate),
    endDate: formatDateParam(period.endDate),
  })
