import { createTheme } from '@mui/material/styles'

// NOTE: accessing to createBreakpoints is not recommended since it is a private module
// https://stackoverflow.com/questions/68554470/was-the-createbreakpoints-method-removed-in-material-version-5
const theme = createTheme()

const typography = {
  fontFamily: ['Inter', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
  h1: {
    fontFamily: 'ClashDisplay-Semibold',
    fontSize: theme.typography.pxToRem(42),
    fontWeight: 600,
    lineHeight: 1,
    letterSpacing: '0.02em',

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(72),
    },
  },
  h2: {
    fontFamily: 'ClashDisplay-Semibold',
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 600,
    lineHeight: 1.1,
    letterSpacing: '0.02em',

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(52),
    },
  },
  h3: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 700,
    lineHeight: 1.4,
  },
  h4: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: 1.2,

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(24),
    },
  },
  h5: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: 1.2,

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  body1: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    lineHeight: 1.5,

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  body2: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    lineHeight: 1.5,

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  subtitle1: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 400,
    lineHeight: 1.2,
  },
  subtitle2: {
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 300,
    lineHeight: 2,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
}

export default typography
