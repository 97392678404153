import { Box } from '@mui/material'

import styles from './layout.styles'

const Layout = ({ children }) => {
  return (
    <Box component='main' aria-label='dashboard' sx={styles.root}>
      <Box sx={styles.mainContent}>{children}</Box>
    </Box>
  )
}

export default Layout
