const REQUIRED = ['REACT_APP_COGNITO_DOMAIN', 'REACT_APP_COGNITO_CLIENT_ID']

if (REQUIRED.some((variable) => process.env[variable] === undefined)) {
  const missing = REQUIRED.filter(
    (variable) => process.env[variable] === undefined,
  )
  throw new Error(`missing env variables => ${missing.join(', ')}`)
}

export const getCognitoSetup = () => ({
  domain: process.env.REACT_APP_COGNITO_DOMAIN,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
})
