import PropTypes from 'prop-types'
import {
  Box,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Chip,
} from '@mui/material'
import styles from './multipleSelectChip.styles'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const sortAlphabeticallyByProp = (list, objProp) =>
  list.sort((a, b) =>
    a[objProp].toLowerCase().localeCompare(b[objProp].toLowerCase()),
  )

const sortByLabel = (list) => sortAlphabeticallyByProp(list, 'label')

const SELECT_ALL_OPTION = {
  value: 'all',
  label: 'Todos',
}

const UNSELECT_ALL_OPTION = {
  value: 'none',
  label: 'Ninguno',
}

const getFinalOptions = (withSelectAll, withUnselectAll, options, selected) => {
  const finalOptions = [...options]
  sortByLabel(finalOptions)

  if (
    withSelectAll &&
    options.length > 1 &&
    options.length !== selected.length
  ) {
    finalOptions.unshift(SELECT_ALL_OPTION)
  }
  if (
    withUnselectAll &&
    options.length > 1 &&
    options.length === selected.length
  ) {
    finalOptions.unshift(UNSELECT_ALL_OPTION)
  }

  return finalOptions
}

const multipleSelectChipPropTypes = {
  label: PropTypes.string,
  withoutLabel: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  setSelected: PropTypes.func.isRequired,
  withSelectAll: PropTypes.bool,
  withUnselectAll: PropTypes.bool,
  formSx: PropTypes.object,
  isDisabled: PropTypes.bool,
}

const MultipleSelectChip = ({
  label,
  withoutLabel,
  options,
  selected,
  setSelected,
  withSelectAll = false,
  withUnselectAll = false,
  formStyles = {},
  isDisabled,
}) => {
  // const theme = useTheme()

  const finalOptions = getFinalOptions(
    withSelectAll,
    withUnselectAll,
    options,
    selected,
  )

  const handleChange = (event) => {
    const value = event.target.value

    if (withSelectAll && value === SELECT_ALL_OPTION.value) {
      setSelected(options.map((opt) => opt.value))
    } else if (withUnselectAll && value === UNSELECT_ALL_OPTION.value) {
      setSelected([])
    } else {
      if (selected.includes(value)) {
        setSelected(selected.filter((opt) => opt !== value))
      } else {
        setSelected([...selected, value])
      }
    }
  }

  return (
    <FormControl style={formStyles}>
      {!Boolean(withoutLabel) && (
        <InputLabel id={`${label}-select-label-id`}>{label}</InputLabel>
      )}
      <Select
        labelId={
          !Boolean(withoutLabel) ? `${label}-select-label-id` : undefined
        }
        label={!Boolean(withoutLabel) ? label : undefined}
        id={`${label}-select-id`}
        value={selected}
        onChange={handleChange}
        input={
          <OutlinedInput label={!Boolean(withoutLabel) ? label : undefined} />
        }
        inputProps={{ 'aria-label': label }}
        renderValue={(selected) => {
          const selectedOptions = options.filter((opt) =>
            selected.includes(opt.value),
          )
          sortByLabel(selectedOptions)

          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selectedOptions.map((opt) => (
                <Chip key={opt.value} label={opt.label} />
              ))}
            </Box>
          )
        }}
        MenuProps={menuProps}
        disabled={isDisabled}
      >
        {finalOptions.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={selected.includes(option.value) ? styles.selectedMenuItem : {}}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

MultipleSelectChip.propTypes = multipleSelectChipPropTypes

export default MultipleSelectChip
