import { Box, Container } from '@mui/material'

import { MultipleSelectChip, Heading } from '../../../components'
import { useTexts } from '../../../../texts'

import styles from './mediaProviderMultiSelector.styles'
import { ReactComponent as Bag } from './Bag.svg'

const MediaProviderMultiSelector = ({ selectorProps }) => {
  const texts = useTexts()

  return (
    <Box sx={styles.multiSelectorContainer}>
      <Container>
        <Heading Icon={<Bag />} text={texts.getMediaProviderTitle()} />
        <MultipleSelectChip
          label={texts.getMediaProviderTitle()}
          withoutLabel
          withSelectAll
          withUnselectAll
          formStyles={styles.multiSelector}
          {...selectorProps}
        />
      </Container>
    </Box>
  )
}

export default MediaProviderMultiSelector
