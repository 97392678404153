const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: {
      xs: 2,
      lg: 2.5,
    },
    mx: 'auto',
  },
  wrapTabs: {
    px: {
      xs: 1.25,
      md: 0,
    },
    boxShadow: 3,
  },
  wrapTabPanel: {
    display: 'flex',
    justifyContent: 'center',
    px: 1.25,
  },
  tabPanel: {
    width: (theme) => `min(100%, ${theme.spacing(22.5)})`,
    py: 1,
  },
}

export default styles
