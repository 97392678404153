import PropTypes from 'prop-types'
import { useState, useEffect, useMemo } from 'react'
import { Box } from '@mui/material'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { useTexts } from '../../../texts'
import { HelperText } from '../index'
import useDatePicker from './useDatePicker'
import { ReactComponent as Warning } from './Warning.svg'
import DatePicker from './DatePicker'
import styles from './dateRangePicker.styles'

const isSameDate = (d1, d2) =>
  d1.getFullYear() === d2.getFullYear() &&
  d1.getMonth() === d2.getMonth() &&
  d1.getDate() === d2.getDate()

const isValidInterval = (startDate, endDate) =>
  startDate < endDate || isSameDate(startDate, endDate)

const dateRangePickerPropTypes = {
  defaultInterval: PropTypes.shape({
    start: PropTypes.object,
    end: PropTypes.object,
  }),
  changeInterval: PropTypes.func,
}

const DateRangePicker = ({ defaultInterval = {}, changeInterval }) => {
  const texts = useTexts()

  const [interval, setInterval] = useState(defaultInterval)

  const handleChangeIntervalStartDate = (date) =>
    setInterval((interval) => ({ ...interval, start: date }))

  const handleChangeIntervalEndDate = (date) =>
    setInterval((interval) => ({ ...interval, end: date }))

  const {
    date: startDate,
    setDate: setStartDate,
    errorMessage: startDateErrorMsg,
    handleChangeDate: handleChangeStartDate,
  } = useDatePicker(handleChangeIntervalStartDate)

  const {
    date: endDate,
    setDate: setEndDate,
    errorMessage: endDateErrorMsg,
    handleChangeDate: handleChangeEndDate,
  } = useDatePicker(handleChangeIntervalEndDate)

  const hasIntervalError = useMemo(
    () =>
      Boolean(startDate) &&
      Boolean(endDate) &&
      !Boolean(startDateErrorMsg) &&
      !Boolean(endDateErrorMsg) &&
      !isValidInterval(startDate, endDate),
    [startDate, endDate, startDateErrorMsg, endDateErrorMsg],
  )

  useEffect(() => {
    changeInterval(interval)
    // eslint-disable-next-line
  }, [interval])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={styles.root}>
        <Box sx={styles.list} component='ol'>
          <DatePicker
            name='startDate'
            component='li'
            date={startDate}
            setDate={setStartDate}
            error={Boolean(startDateErrorMsg) || hasIntervalError}
            errorMessage={startDateErrorMsg}
            handleChangeDate={handleChangeStartDate}
          />
          <DatePicker
            name='endDate'
            component='li'
            date={endDate}
            setDate={setEndDate}
            error={Boolean(endDateErrorMsg) || hasIntervalError}
            errorMessage={endDateErrorMsg}
            handleChangeDate={handleChangeEndDate}
          />
        </Box>

        {hasIntervalError ? (
          <HelperText
            variant='error'
            Icon={<Warning />}
            text={texts.getInvalidIntervalError()}
          />
        ) : null}
      </Box>
    </LocalizationProvider>
  )
}

DateRangePicker.propTypes = dateRangePickerPropTypes

export default DateRangePicker
