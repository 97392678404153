import {
  ListItemIcon,
  ListItemText,
  Menu as MuiMenu,
  MenuItem,
} from '@mui/material'

import { useTexts } from '../../../texts'
import { ReactComponent as Logout } from './Logout.svg'
import { ReactComponent as UserCircle } from './UserCircle.svg'

const Menu = ({
  widerThanTablet,
  anchorEl,
  open,
  handleClose,
  logout,
  goToConfig,
}) => {
  const texts = useTexts()

  const onClickMenuItem = (handler) => {
    handleClose()
    handler()
  }

  return (
    <MuiMenu
      id='user-menu'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'user-button',
      }}
    >
      <MenuItem
        onClick={() => onClickMenuItem(goToConfig)}
        aria-label='configuration-button'
      >
        <ListItemIcon>
          <UserCircle />
        </ListItemIcon>
        <ListItemText>{texts.getConfigurationTitle()}</ListItemText>
      </MenuItem>

      {widerThanTablet ? null : (
        <MenuItem
          onClick={() => onClickMenuItem(logout)}
          aria-label='logout-button'
        >
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText>{texts.getUserLogoutAction()}</ListItemText>
        </MenuItem>
      )}
    </MuiMenu>
  )
}

export default Menu
