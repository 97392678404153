const styles = {
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    pt: {
      xs: 3,
      lg: 3.5,
    },
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    // eslint-disable-next-line no-dupe-keys
    height: '100svh',

    // NOTE: At this moment Firefox does not support :has() selector
    // but we use it because this selector is ornamental, not a big deal
    // Furthermore, it is planned that Firefox will support :has() in the first half of 2023
    '&:has([aria-labelledby])': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}

export default styles
