import MuiButton from './MuiButton'
import MuiButtonBase from './MuiButtonBase'
import MuiCheckbox from './MuiCheckbox'
import MuiCircularProgress from './MuiCircularProgress'
import MuiCssBaseline from './MuiCssBaseline'
import MuiContainer from './MuiContainer'
import MuiDatePicker from './MuiDatePicker'
import MuiDialog from './MuiDialog'
import MuiFilledInput from './MuiFilledInput'
import MuiFormControlLabel from './MuiFormControlLabel'
import MuiFormHelperText from './MuiFormHelperText'
import MuiIconButton from './MuiIconButton'
import MuiLink from './MuiLink'
import MuiListItemText from './MuiListItemText'
import MuiMenu from './MuiMenu'
import MuiMenuItem from './MuiMenuItem'
import MuiPagination from './MuiPagination'
import MuiPaginationItem from './MuiPaginationItem'
import MuiTableCell from './MuiTableCell'
import MuiTableContainer from './MuiTableContainer'
import MuiTablePagination from './MuiTablePagination'
import MuiInputAdornment from './MuiInputAdornment'
import MuiInputBase from './MuiInputBase'
import MuiInputLabel from './MuiInputLabel'
import MuiLoadingButton from './MuiLoadingButton'
import MuiTab from './MuiTab'
import MuiTabs from './MuiTabs'
import MuiTextField from './MuiTextField'
import MuiTypography from './MuiTypography'

const components = {
  MuiButton,
  MuiButtonBase,
  MuiCheckbox,
  MuiCircularProgress,
  MuiContainer,
  MuiCssBaseline,
  MuiDatePicker,
  MuiDialog,
  MuiFilledInput,
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiIconButton,
  MuiLink,
  MuiListItemText,
  MuiMenu,
  MuiMenuItem,
  MuiPagination,
  MuiPaginationItem,
  MuiTableCell,
  MuiTableContainer,
  MuiTablePagination,
  MuiInputAdornment,
  MuiInputBase,
  MuiInputLabel,
  MuiLoadingButton,
  MuiTab,
  MuiTabs,
  MuiTextField,
  MuiTypography,
}

export default components
