import { getAccessToken } from './authentication'

const BACKOFFICE_ENDPOINT = process.env.REACT_APP_BACKOFFICE_ENDPOINT

export const getPurchases = async (mediaProviderIds, pagination, filters) => {
  const searchParams = new URLSearchParams({ ...pagination, ...filters })
  for (const id of mediaProviderIds) {
    searchParams.append('mediaId', id)
  }

  const response = await fetch(
    `${BACKOFFICE_ENDPOINT}/media-provider-admin/article-purchases?${searchParams.toString()}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  )

  if (response.ok) {
    return await response.json().then((resp) => ({
      ...resp,
      data: resp.data.map((datum) => ({
        ...datum,
        userName: datum.userFullName,
        date: new Intl.DateTimeFormat('default').format(new Date(datum.date)),
      })),
    }))
  } else {
    throw new Error(await response.json().then((r) => r.error))
  }
}
export const getAllPurchases = async (pagination, filters) =>
  getPurchases(
    { orderBy: pagination.orderBy, orderDirection: pagination.orderDirection },
    filters,
  )
