import { useState, useEffect } from 'react'

import { getMonthUniqueUsers, getMonthNewUsers } from '../../../../services'
import { useTexts } from '../../../../texts'
import { CardGroup, Card } from '../../../components'
import formatCardContent from './formatCardContent'
import { ReactComponent as UserCircle } from './UserCircle.svg'

const UsersCardGroup = ({ mediaProviderIds }) => {
  const texts = useTexts()

  const [uniqueUsers, setUniqueUsers] = useState()
  const [newUsers, setNewUsers] = useState()

  useEffect(() => {
    ;(async () => {
      if (Boolean(mediaProviderIds)) {
        setUniqueUsers(await getMonthUniqueUsers(mediaProviderIds))
        setNewUsers(await getMonthNewUsers(mediaProviderIds))
      }
    })()
  }, [mediaProviderIds])

  return (
    <CardGroup title={texts.getUsersTitle()} icon={<UserCircle />}>
      {uniqueUsers ? (
        <Card
          title={texts.getCardUniqueTitle()}
          balanceLabel={texts.getCardLastMonthLabel()}
          {...formatCardContent(uniqueUsers)}
        />
      ) : null}
      {newUsers ? (
        <Card
          title={texts.getCardNewTitle()}
          balanceLabel={texts.getCardLastMonthLabel()}
          {...formatCardContent(newUsers)}
        />
      ) : null}
    </CardGroup>
  )
}

export default UsersCardGroup
