const MuiTypography = {
  defaultProps: {
    variantMapping: {
      subtitle1: 'p',
      subtitle2: 'p',
    },
  },
}

export default MuiTypography
