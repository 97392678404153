import baseTheme from '../base'

const MuiInputBase = {
  styleOverrides: {
    root: {
      '&.Mui-error': {
        color: baseTheme.palette.error.main,
      },
    },
  },
}

export default MuiInputBase
