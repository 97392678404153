import PropTypes from 'prop-types'
import {
  Checkbox as MuiCheckbox,
  FormHelperText,
  FormControlLabel,
  FormControl,
} from '@mui/material'

import { useTexts } from '../../../texts'
import { ReactComponent as CheckboxDefault } from './Checkbox.svg'
import { ReactComponent as CheckboxActive } from './CheckboxActive.svg'
import { ReactComponent as Warning } from './Warning.svg'
import styles from './checkbox.styles'

const checkboxPropTypes = {
  skin: PropTypes.oneOf(['dark', 'light']),
  size: PropTypes.oneOf(['small', 'medium']),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
}

const Checkbox = ({ skin, size, label, sx, error, required, ...rest }) => {
  const texts = useTexts()

  const labelComposition = required ? (
    label
  ) : (
    <>
      {label} <b>• {texts.getOptionalLabel()}</b>
    </>
  )

  return (
    <FormControl error={Boolean(error)} sx={sx}>
      <FormControlLabel
        label={labelComposition}
        control={
          <MuiCheckbox
            icon={<CheckboxDefault />}
            checkedIcon={<CheckboxActive />}
            required={required}
          />
        }
        sx={{ ...styles[skin], ...styles[size] }}
        {...rest}
      />
      {error ? (
        <FormHelperText>
          <Warning />
          {error}
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}

Checkbox.defaultProps = {
  skin: 'light',
  size: 'small',
}

Checkbox.propTypes = checkboxPropTypes

export default Checkbox
