import { useState, useMemo } from 'react'

const getFieldsErrors = (formValues, formValidationConfig) => {
  let fieldErrors = {}

  for (const [fieldName, fieldConfig] of Object.entries(formValidationConfig)) {
    fieldErrors[fieldName] = fieldConfig(formValues[fieldName])
  }
  return fieldErrors
}

const hasFieldErrors = (fieldErrors) =>
  Object.values(fieldErrors).some((value) => value !== '')

const sanitizeFormValues = (values) => {
  const sanitizedState = {}
  for (const [key, value] of Object.entries(values)) {
    sanitizedState[key] = value.trim()
  }
  return sanitizedState
}

const DEFAULT_STATE = {
  isSubmitting: false,
  formValues: {},
  formError: '',
  fieldErrors: {},
  submitStatus: null,
  result: null,
}

const useForm = (fetchFn, formValidationConfig, initialValues = {}) => {
  const defaultState = { ...DEFAULT_STATE, formValues: initialValues }
  const [status, setStatus] = useState(defaultState)

  const isSubmitSuccess = useMemo(
    () => status.submitStatus === 'success',
    [status.submitStatus],
  )

  const isSubmitError = useMemo(
    () => status.submitStatus === 'failure',
    [status.submitStatus],
  )

  const isSubmitDone = useMemo(
    () => status.submitStatus !== null,
    [status.submitStatus],
  )

  const submitForm = async (formValues) => {
    const sanitizedValues = sanitizeFormValues(formValues)

    setStatus({
      ...DEFAULT_STATE,
      isSubmitting: true,
      sanitizedValues,
    })

    const fieldErrors = getFieldsErrors(sanitizedValues, formValidationConfig)

    if (hasFieldErrors(fieldErrors)) {
      setStatus((status) => ({ ...status, fieldErrors, isSubmitting: false }))
      return
    }

    try {
      const result = await fetchFn(sanitizedValues)
      setStatus((status) => ({
        ...status,
        submitStatus: 'success',
        isSubmitting: false,
        result,
      }))
    } catch (error) {
      setStatus((status) => ({
        ...status,
        submitStatus: 'failure',
        isSubmitting: false,
        formError: error.message,
      }))
    }
  }

  const resetState = () => setStatus(DEFAULT_STATE)

  return {
    status,
    isSubmitSuccess,
    isSubmitError,
    isSubmitDone,
    submitForm,
    resetState,
  }
}

export default useForm
