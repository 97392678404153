import { useState, useEffect } from 'react'

import { useTexts } from '../../../texts'

const isPastDate = (date) => date.getTime() < new Date().getTime()

const isCompleteDate = (date) =>
  Boolean(date) && new Date(1000, 0).getTime() < date.getTime()

const isValidDate = (date) => {
  if (date.toString() === 'Invalid Date') return false
  return isPastDate(date) && isCompleteDate(date)
}

const useDatePicker = (onChangeDate) => {
  const texts = useTexts()

  const [date, setDate] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const handleChangeDate = (newDate) => {
    setDate(newDate)

    if (!Boolean(newDate) || isValidDate(newDate)) {
      setErrorMessage('')
    } else {
      setErrorMessage(texts.getInvalidDateError())
    }
  }

  useEffect(() => {
    const cleanedDate = !Boolean(date) || !isValidDate(date) ? null : date
    onChangeDate(cleanedDate)
    // eslint-disable-next-line
  }, [date])

  return {
    date,
    setDate,
    errorMessage,
    handleChangeDate,
  }
}

export default useDatePicker
