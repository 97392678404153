import { Box, IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useTexts } from '../../../../texts'
import {
  Table,
  Heading,
  DateRangePicker,
  EmptyState,
  CircularProgress,
} from '../../../components'
import { ReactComponent as Cardholder } from './Cardholder.svg'
import { ReactComponent as DownloadFile } from './DownloadFile.svg'
import styles from './purchasesTable.styles'

const PurchasesTableView = ({
  showNoDataView,
  showNoFilteredDataView,
  changeInterval,
  defaultInterval,
  downloadExcel,
  ...tableProps
}) => {
  const theme = useTheme()
  const texts = useTexts()
  const widerThanDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <Box component='section' sx={styles.root}>
      <Box component='header' sx={styles.header}>
        <Heading
          withPaddingLeft
          Icon={<Cardholder />}
          text={texts.getPurchasesTitle()}
        />
        {widerThanDesktop && !showNoDataView ? (
          <Box sx={styles.controls}>
            <DateRangePicker
              changeInterval={changeInterval}
              defaultInterval={defaultInterval}
            />
            <IconButton
              aria-label={texts.getDownloadExcelAriaLabel()}
              size='small'
              onClick={downloadExcel}
              style={styles.downloadExcelBtn}
            >
              <DownloadFile />
            </IconButton>
          </Box>
        ) : null}
      </Box>
      {!Boolean(tableProps.data) ? (
        <CircularProgress />
      ) : showNoDataView ? (
        <EmptyState
          title={texts.getNoPurchasesTitleLabel()}
          subtitle={texts.getNoPurchasesTextLabel()}
        />
      ) : showNoFilteredDataView ? (
        <EmptyState
          title={texts.getNoFilteredPurchasesTitleLabel()}
          subtitle={texts.getNoFilteredPurchasesTextLabel()}
        />
      ) : (
        <Table {...tableProps} />
      )}
    </Box>
  )
}

export default PurchasesTableView
