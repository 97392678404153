import { Tabs } from '../../components'
import { useTexts } from '../../../texts'
// import MediaDataEdition from './MediaDataEdition'
import PasswordChange from './PasswordChange'
// import TokenGenerator from './TokenGenerator'
// import SecretRegenerator from './SecretRegenerator'

const Configuration = () => {
  const texts = useTexts()

  const tabsConfig = [
    // {
    //   label: texts.getMediaDataTitle(),
    //   content: <MediaDataEdition />,
    // },
    {
      label: texts.getChangePasswordTitle(),
      content: <PasswordChange />,
    },
    // {
    //   label: texts.getGenerateTokenTitle(),
    //   content: <TokenGenerator />,
    // },
    // {
    //   label: texts.getRegenerateSecretTitle(),
    //   content: <SecretRegenerator />,
    // },
  ]

  return <Tabs ariaLabel={texts.getTabsAriaLabel()} tabs={tabsConfig} />
}

export default Configuration
