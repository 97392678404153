const styles = {
  root: {
    pt: 2,
    position: 'relative',

    '&::after': {
      content: '""',
      display: 'block',
      width: '100vw',
      height: {
        xs: '90%',
        xl: '75%',
      },
      backgroundColor: 'neutral.200',
      position: 'absolute',
      top: 0,
      left: 'calc(50% - 50vw)',
      zIndex: -1,
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      xl: 'row',
    },
    gap: 1.5,
  },
  chart: {
    width: '100%',
    maxWidth: '100%',
    overflowX: 'scroll',
    marginTop: 40,
  },
}

export default styles
