const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 0.625,
  },
  withPaddingLeft: {
    pl: 1.25,
  },
  icon: {
    display: 'flex',
    fontSize: (theme) => ({
      xs: theme.spacing(1),
      md: theme.spacing(1.25),
    }),
  },
}

export default styles
