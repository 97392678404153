import { useState, useEffect } from 'react'

import { getPurchases, getAllPurchases } from '../../../../services'
import { formatDateParam } from '../../../format'
import { exportPurchasesTableExcel } from './exportExcel'
import {
  DEFAULT_PAGINATION,
  PURCHASE_TABLE_HEAD_DATA,
  PURCHASE_TABLE_ROWS_PER_PAGE_OPTIONS,
} from './constants'
import View from './View'

const DEFAULT_INTERVAL = {}

const getFilters = (interval) => {
  const filters = {}
  if (Boolean(interval.start)) {
    filters.startDate = formatDateParam(interval.start)
  }
  if (Boolean(interval.end)) {
    filters.endDate = formatDateParam(interval.end)
  }
  return filters
}

const PurchasesTable = ({ mediaProviderIds }) => {
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
  const [filters, setFilters] = useState(getFilters(DEFAULT_INTERVAL))
  const [totalCount, setTotalCount] = useState(0)
  const [purchases, setPurchases] = useState()

  const handleChangePage = (newOffset) =>
    setPagination((pagination) => ({ ...pagination, offset: newOffset }))

  const handleChangeRowsPerPage = (newLimit) =>
    setPagination((pagination) => ({
      ...pagination,
      limit: newLimit,
      offset: 0,
    }))

  const handleChangeOrderBy = (newOrderBy) =>
    setPagination((pagination) => ({
      ...pagination,
      orderBy: newOrderBy,
      orderDirection: 'DESC',
    }))

  const handleChangeOrderDirection = () => {
    setPagination((pagination) => ({
      ...pagination,
      orderDirection: pagination.orderDirection === 'DESC' ? 'ASC' : 'DESC',
    }))
  }

  const handleChangeInterval = (newInterval) => {
    setFilters(getFilters(newInterval))
    handleChangePage(0)
  }

  const fetchPurchases = async () => {
    const purchasesResp = await getPurchases(
      mediaProviderIds,
      pagination,
      filters,
    )

    setPurchases(purchasesResp.data)
    setTotalCount(purchasesResp.totalCount)
  }

  useEffect(() => {
    ;(async () => {
      if (Boolean(mediaProviderIds)) {
        await fetchPurchases()
      }
    })()
    // eslint-disable-next-line
  }, [mediaProviderIds, pagination, filters])

  const handleDownloadExcel = async () => {
    const allFilteredPurchasesResp = await getAllPurchases(pagination, filters)
    exportPurchasesTableExcel(allFilteredPurchasesResp.data, filters)
  }

  return (
    <View
      showNoDataView={
        purchases && purchases.length === 0 && Object.keys(filters).length === 0
      }
      showNoFilteredDataView={
        purchases && purchases.length === 0 && Object.keys(filters).length !== 0
      }
      downloadExcel={handleDownloadExcel}
      changeInterval={handleChangeInterval}
      defaultInterval={DEFAULT_INTERVAL}
      headData={PURCHASE_TABLE_HEAD_DATA}
      rowsPerPageOptions={PURCHASE_TABLE_ROWS_PER_PAGE_OPTIONS}
      data={purchases}
      totalCount={totalCount}
      page={pagination.offset}
      changePage={handleChangePage}
      rowsPerPage={pagination.limit}
      changeRowsPerPage={handleChangeRowsPerPage}
      orderBy={pagination.orderBy}
      changeOrderBy={handleChangeOrderBy}
      orderDirection={pagination.orderDirection.toLowerCase()}
      changeOrderDirection={handleChangeOrderDirection}
    />
  )
}

export default PurchasesTable
