import { createContext, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import * as api from '../services'

const UserContext = createContext(null)

export const useUserContext = () => useContext(UserContext)

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState()

  const updateMediaProviderEditableData = async (mediaId, editableData) => {
    await api.updateMediaProvider(mediaId, editableData)

    const editedIndex = user.mediaProviders.data.findIndex(
      (media) => media.id === mediaId,
    )
    const newMediaProvidersData = [...user.mediaProviders.data]
    newMediaProvidersData[editedIndex] = {
      ...newMediaProvidersData[editedIndex],
      ...editableData,
    }

    setUser((user) => ({
      ...user,
      mediaProviders: {
        ...user.mediaProviders,
        data: newMediaProvidersData,
      },
    }))
  }

  useEffect(() => {
    ;(async () => {
      try {
        const user = await api.getUserIdentity()
        const { mediaProviderIds, ...userIdentity } = user

        const mediaProvidersData = []
        for (const mediaProviderId of mediaProviderIds) {
          const mediaProvider = await api.getMediaProvider(mediaProviderId)
          mediaProvidersData.push(mediaProvider)
        }

        setUser({
          ...userIdentity,
          mediaProviders: {
            ids: mediaProviderIds,
            data: mediaProvidersData,
          },
        })
      } catch {
        await api.logout()
      }
    })()
  }, [])

  return (
    <UserContext.Provider
      value={{
        user,
        updateMediaProviderEditableData,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
