const palette = {
  primary: {
    darkest: '#CBA301',
    dark: '#E7B800',
    main: '#FFCC00',
  },
  neutral: {
    100: '#FFFFFF',
    200: '#F7F7F5',
    300: '#EFEFED',
    400: '#969594',
    500: '#4D4C4C',
    600: '#343434',
    700: '#121212',
  },
  error: {
    darkest: '#4A0D00',
    dark: '#B02709',
    main: '#E76245',
    light: '#FFE0E0',
    lightest: '#FEF7F5',
  },
  success: {
    dark: '#67A586',
    main: '#75D7A6',
    light: '#E0FFF0',
    lightest: '#F7FCF9',
  },
  info: {
    dark: '#7A69DE',
    main: '#8C7BF2',
    light: '#E5E0FF',
    lightest: '#FBFAFF',
  },
  warning: {
    dark: '#BE6904',
    main: '#EF8F1E',
    light: '#FFF1E0',
    lightest: '#FFFBF7',
  },
}

export default palette
