import PropTypes from 'prop-types'
import {
  Box,
  IconButton,
  Link,
  TablePagination,
  Pagination,
  PaginationItem,
  TableSortLabel,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTexts } from '../../../texts'

import { ReactComponent as CaretRight } from './CaretRight.svg'
import { ReactComponent as CaretDoubleRight } from './CaretDoubleRight.svg'
import { ReactComponent as CaretLeft } from './CaretLeft.svg'
import { ReactComponent as CaretDoubleLeft } from './CaretDoubleLeft.svg'
import { ReactComponent as CaretSelectDown } from './CaretSelectDown.svg'
import styles from './table.styles'

const TablePaginationPropTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}

const TablePaginationMobileActions = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}) => {
  const texts = useTexts()

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  return (
    <Box sx={styles.tablePaginationActions}>
      <IconButton
        disabled={page === 0}
        onClick={handleBackButtonClick}
        aria-label={texts.getPurchasesPrevPageLabel()}
      >
        <CaretLeft />
      </IconButton>
      <IconButton
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
        aria-label={texts.getPurchasesNextPageLabel()}
      >
        <CaretRight />
      </IconButton>
    </Box>
  )
}

TablePaginationMobileActions.propTypes = TablePaginationPropTypes

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const numberOfPages = Math.ceil(count / rowsPerPage)

  //MUI Pagination is 1-indexed while MUI TablePagination is 0-indexed
  const handleChangePageFromPagination = (event, value) =>
    onPageChange(event, value - 1)

  return (
    <Pagination
      count={numberOfPages}
      //MUI Pagination is 1-indexed while MUI TablePagination is 0-indexed
      page={page + 1}
      onChange={handleChangePageFromPagination}
      siblingCount={1}
      boundaryCount={1}
      showFirstButton
      showLastButton
      renderItem={(item) => (
        <PaginationItem
          components={{
            first: CaretDoubleLeft,
            previous: CaretLeft,
            next: CaretRight,
            last: CaretDoubleRight,
          }}
          {...item}
        />
      )}
    />
  )
}

TablePaginationActions.propTypes = TablePaginationPropTypes

const TablePropTypes = {
  headData: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      width: PropTypes.number,
    }).isRequired,
  ),
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      headline: PropTypes.string.isRequired,
      mediaProvider: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      userName: PropTypes.string.isRequired,
    }).isRequired,
  ),
  totalCount: PropTypes.number,
  rowsPerPage: PropTypes.number,
  changeRowsPerPage: PropTypes.func,
  page: PropTypes.number,
  changePage: PropTypes.func,
  orderBy: PropTypes.string,
  changeOrderBy: PropTypes.func,
  orderDirection: PropTypes.oneOf(['asc', 'desc']),
  changeOrderDirection: PropTypes.func,
}

const Table = ({
  headData,
  rowsPerPageOptions,
  data,
  totalCount,
  rowsPerPage,
  changeRowsPerPage,
  page,
  changePage,
  orderBy,
  changeOrderBy,
  orderDirection,
  changeOrderDirection,
}) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.up('sm'))
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))

  const texts = useTexts()

  const handleClickSort = (newOrderBy) => {
    if (newOrderBy === orderBy) {
      changeOrderDirection()
    } else {
      changeOrderBy(newOrderBy)
    }
  }

  return (
    <Box role='table'>
      <Box role='rowgroup' aria-label={texts.getPurchasesTableLabel()}>
        <Box role='row' sx={styles.tableHeaderRow}>
          {headData.map((columnData) => (
            <Box
              key={columnData.field}
              role='columnheader'
              sx={styles.tableHeaderCell}
              data-field={columnData.field}
            >
              <TableSortLabel
                active={columnData.field === orderBy}
                direction={orderDirection}
                onClick={() => handleClickSort(columnData.field)}
              >
                {columnData.label}
              </TableSortLabel>
            </Box>
          ))}
        </Box>

        <Box role='rowgroup'>
          {data.map((rowData) => (
            <Box role='row' key={rowData.id} sx={styles.tableBodyRow}>
              {headData.map((columnData) => (
                <Box
                  key={columnData.field}
                  role='cell'
                  sx={styles.tableBodyCell}
                  data-field={columnData.field}
                >
                  {columnData.field === 'headline' && rowData.url ? (
                    <Link
                      href={rowData.url}
                      sx={styles.tableLink}
                      title={rowData[columnData.field]}
                      target='_blank'
                      rel='noreferrer noopener'
                    >
                      {rowData[columnData.field]}
                    </Link>
                  ) : columnData.field === 'price' ? (
                    rowData[columnData.field] + ' €'
                  ) : (
                    rowData[columnData.field]
                  )}
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
      <TablePagination
        page={page}
        count={totalCount}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        labelRowsPerPage={isSmall ? texts.getPurchasesRowsPerPageLabel() : null}
        labelDisplayedRows={({ from, to, count }) =>
          isLarge
            ? texts.getPurchasesDisplayedRowsLongDescriptionLabel(
                from,
                to,
                count,
              )
            : texts.getPurchasesDisplayedRowsLabel(from, to, count)
        }
        ActionsComponent={
          isLarge ? TablePaginationActions : TablePaginationMobileActions
        }
        SelectProps={{
          inputProps: {
            'aria-label': texts.getPurchasesRowsPerPageLabel(),
          },
          IconComponent: CaretSelectDown,
          native: true,
        }}
        onPageChange={(_, newPage) => changePage(newPage)}
        onRowsPerPageChange={(event) =>
          changeRowsPerPage(parseInt(event.target.value, 10))
        }
      />
    </Box>
  )
}

Table.propTypes = TablePropTypes

export default Table
