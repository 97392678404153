const separator = {
  position: 'relative',

  '&::after': {
    content: '""',
    display: {
      xs: 'block',
      lg: 'none',
    },
    height: '100%',
    width: '1px',
    position: 'absolute',
    right: '-7px',
    top: 0,
    backgroundColor: 'neutral.300',
  },
}

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    pb: {
      xs: 4,
      lg: 8.5,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1.5,
    width: (theme) => `min(100%, ${theme.spacing(79)})`,
    px: {
      xs: 0,
      md: 2,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 0.625,
    pl: 1.5,
  },
  icon: {
    display: 'flex',
    fontSize: '1.25rem',
  },
  title: {
    fontSize: (theme) => ({
      xs: theme.typography.pxToRem(20),
      md: theme.typography.pxToRem(20),
    }),
    fontWeight: 600,
    lineHeight: 1.2,
  },
  tableHeaderRow: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'auto 1fr auto auto',
      lg: '80px 1fr min(15%, 100px) min(15%, 60px)',
    },
    gridTemplateRows: '1fr',
    gridTemplateAreas: {
      xs: '"headline userName date price"',
      lg: '"date headline userName price"',
    },
    columnGap: {
      xs: 0.75,
      lg: 3,
    },
    pt: {
      xs: 0.85,
      lg: 0.75,
    },
    px: {
      xs: 1.25,
      lg: 1.5,
    },
    pb: {
      xs: 0.5,
    },
    mb: 0.5,
    borderBottom: '1px solid rgba(156, 156, 156, 0.2)',
  },
  tableHeaderCell: {
    fontSize: (theme) => ({
      xs: theme.spacing(0.75),
      lg: theme.spacing(0.875),
    }),
    fontWeight: 400,
    color: 'neutral.500',

    '&[data-field="date"]': {
      ...separator,
      gridArea: 'date',
    },
    '&[data-field="headline"]': {
      ...separator,
      gridArea: 'headline',
    },
    '&[data-field="userName"]': {
      gridArea: 'userName',
    },
    '&[data-field="price"]': {
      gridArea: 'price',
    },
  },
  tableBodyRow: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr 80px',
      lg: '80px 1fr min(15%, 100px) min(15%, 60px)',
    },
    gridTemplateRows: {
      xs: 'auto 1fr',
      lg: '1fr',
    },
    gridTemplateAreas: {
      xs: '"headline date" "userName price"',
      lg: '"date headline userName price"',
    },
    py: {
      xs: 0.5,
      lg: 1,
    },
    px: {
      xs: 1.25,
      lg: 1.5,
    },
    rowGap: {
      xs: 0.125,
      lg: 0,
    },
    columnGap: {
      xs: 1,
      lg: 3,
    },
  },
  tableBodyCell: {
    fontSize: (theme) => ({
      xs: theme.spacing(0.75),
      lg: theme.spacing(0.875),
    }),
    fontWeight: 400,
    color: 'neutral.500',

    '&[data-field="date"]': {
      gridArea: 'date',
      textAlign: {
        xs: 'right',
        lg: 'left',
      },
    },
    '&[data-field="headline"]': {
      gridArea: 'headline',
      fontSize: (theme) => theme.spacing(0.875),
      fontWeight: 500,
      color: 'neutral.700',
    },
    '&[data-field="userName"]': {
      gridArea: 'userName',
      fontSize: (theme) => theme.spacing(0.875),
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '&[data-field="price"]': {
      gridArea: 'price',
      fontWeight: 400,
      textAlign: {
        xs: 'right',
        lg: 'center',
      },
    },
  },
  tableLink: {
    color: 'inherit',
  },
  tablePaginationActions: {
    display: 'flex',
    columnGap: 0.5,

    '& .MuiIconButton-root': {
      p: 0.125,
      borderRadius: (theme) => theme.spacing(0.125),
      color: 'neutral.600',

      '&:hover, &:focus': {
        color: 'neutral.700',
        backgroundColor: 'neutral.200',
      },

      '&.Mui-selected': {
        color: 'neutral.700',
        backgroundColor: 'neutral.300',
      },
      '&.Mui-disabled': {
        color: 'neutral.400',
        opacity: 1,
      },
    },
  },
}

export default styles
