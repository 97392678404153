import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

import { ReactComponent as WarningCircle } from './WarningCircle.svg'
import styles from './helperText.styles'

const helperTextPropTypes = {
  variant: PropTypes.oneOf(['info', 'error']),
  Icon: PropTypes.node,
  text: PropTypes.string.isRequired,
}

const HelperText = ({ variant = 'info', Icon, text }) => (
  <Box sx={{ ...styles.root, ...styles[variant] }}>
    <Box sx={styles.icon}>{Icon ?? <WarningCircle />}</Box>
    <Typography component='span' sx={styles.text}>
      {text}
    </Typography>
  </Box>
)

HelperText.propTypes = helperTextPropTypes

export default HelperText
