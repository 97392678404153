import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
} from 'amazon-cognito-identity-js'

import { getCognitoSetup } from '../environment'

const CognitoSetup = getCognitoSetup()

class AuthServiceCognito {
  pool = new CognitoUserPool({
    UserPoolId: CognitoSetup.userPoolId,
    ClientId: CognitoSetup.clientId,
  })

  async changePassword(email, oldPassword, newPassword) {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.pool,
    })

    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: oldPassword,
    })

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          cognitoUser.changePassword(
            oldPassword,
            newPassword,
            function (error, result) {
              if (error) {
                reject(error)
                return
              }
              resolve()
            },
          )
        },
        onFailure: (error) => {
          reject(error)
        },
      })
    })
  }
}

export default AuthServiceCognito
