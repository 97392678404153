const styles = {
  dark: {
    '--WebkitTextFillColor': (theme) => theme.palette.neutral[100],
    //
    '--label--color': (theme) => theme.palette.neutral[300],
    '--label--backgroundColor': (theme) => theme.palette.neutral[600],
    '--label-error--color': (theme) => theme.palette.error.main,
    '--label-error--backgroundColor': (theme) => theme.palette.error.darkest,
    '--label-focused--color': (theme) => theme.palette.neutral[300],
    '--label-focused--backgroundColor': (theme) => theme.palette.neutral[500],
    '--label-focused-error--color': (theme) => theme.palette.error.main,
    '--label-focused-error--backgroundColor': (theme) =>
      theme.palette.error.darkest,
    //
    '--input--color': (theme) => theme.palette.neutral[100],
    '--input--backgroundColor': (theme) => theme.palette.neutral[600],
    '--input__hover--color': (theme) => theme.palette.neutral[500],
    '--input__hover--backgroundColor': (theme) => theme.palette.neutral[600],
    '--input-focused--backgroundColor': (theme) => theme.palette.neutral[500],
    '--input-error--color': (theme) => theme.palette.error.main,
    '--input-error--backgroundColor': (theme) => theme.palette.error.darkest,
    '--input-error__hover--borderColor': (theme) => theme.palette.error.darkest,
    '--input-error__hover--backgroundColor': (theme) =>
      theme.palette.error.darkest,
    '--input-error-input--color': (theme) => theme.palette.error.light,
  },
  light: {
    '--WebkitTextFillColor': (theme) => theme.palette.neutral[700],
    //
    '--label--color': (theme) => theme.palette.neutral[500],
    '--label--backgroundColor': (theme) => theme.palette.neutral[200],
    '--label-error--color': (theme) => theme.palette.error.main,
    '--label-error--backgroundColor': (theme) => theme.palette.error.lightest,
    '--label-focused--color': (theme) => theme.palette.neutral[500],
    '--label-focused--backgroundColor': (theme) => theme.palette.neutral[300],
    '--label-focused-error--color': (theme) => theme.palette.error.main,
    '--label-focused-error--backgroundColor': (theme) =>
      theme.palette.error.lightest,
    //
    '--input--color': (theme) => theme.palette.neutral[500],
    '--input--backgroundColor': (theme) => theme.palette.neutral[200],
    '--input__hover--color': (theme) => theme.palette.neutral[300],
    '--input__hover--backgroundColor': (theme) => theme.palette.neutral[200],
    '--input-focused--backgroundColor': (theme) => theme.palette.neutral[300],
    '--input-error--color': (theme) => theme.palette.error.main,
    '--input-error--backgroundColor': (theme) => theme.palette.error.lightest,
    '--input-error__hover--borderColor': (theme) =>
      theme.palette.error.lightest,
    '--input-error__hover--backgroundColor': (theme) =>
      theme.palette.error.lightest,
    '--input-error-input--color': (theme) => theme.palette.error.darkest,
  },
}

export default styles
