const styles = {
  root: {
    display: 'inline-flex',
    flexDirection: 'column',
    rowGap: 0.5,
  },
  list: {
    display: 'flex',
    columnGap: 0.0625,
    my: 0,
    pl: 0,
    listStyleType: 'none',
    position: 'relative',

    '&::after': {
      content: '""',
      display: 'block',
      width: (theme) => theme.spacing(0.5),
      height: (theme) => theme.spacing(2.5),
      backgroundColor: 'neutral.300',
      position: 'absolute',
      top: 1,
      left: 'calc(50% - 4px)',
      zIndex: -1,
    },

    // NOTE: Remove some border radius when being used as a range
    '& > li:nth-of-type(1) > div': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    '& > li:nth-of-type(2) > div': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  wrapItem: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0.5,
  },
  item: {
    '--input--color': (theme) => theme.palette.neutral[700],
    '--input__icon--color': (theme) => theme.palette.neutral[500],
    '--input--borderColor': 'transparent',
    '--input--backgroundColor': (theme) => theme.palette.neutral[200],

    borderRadius: (theme) => theme.spacing(0.5),
    border: `1px solid var(--input--borderColor)`,
    boxShadow: 0,
    overflow: 'hidden',
    transition: 'all 300ms',

    '&:hover': {
      '--input--borderColor': (theme) => theme.palette.neutral[300],
      boxShadow: 3,
    },
  },
  itemError: {
    '--input--color': (theme) => theme.palette.error.darkest,
    '--input__icon--color': (theme) => theme.palette.error.main,
    '--input--borderColor': (theme) => theme.palette.error.lightest,
    '--input--backgroundColor': (theme) => theme.palette.error.lightest,

    '&:hover': {
      '--input--borderColor': (theme) => theme.palette.error.lightest,
    },
  },
  wrapControls: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.625,
    pl: 0.75,
    backgroundColor: 'var(--input--backgroundColor)',

    '&:has(button:focus-visible):has(input:not([aria-invalid="true"])), &:has(input:focus-visible:not([aria-invalid="true"]))':
      {
        '--input--backgroundColor': (theme) => theme.palette.neutral[300],
      },
  },
  wrapButton: {
    display: 'flex',
    color: 'var(--input__icon--color)',

    '& .MuiInputAdornment-root': {
      height: 'auto',
      ml: 0,
    },
    '& .MuiButtonBase-root.MuiIconButton-root': {
      p: 0.125,
      mr: 0,
      fontSize: (theme) => theme.spacing(1.25),
      color: 'inherit',
    },
  },
  input: {
    width: (theme) => theme.spacing(7),
    fontSize: '1rem',
    lineHeight: 1.5,
    padding: '4px 12px 4px 0',
    color: 'var(--input--color)',
    border: 0,
    backgroundColor: 'transparent',

    '&.Mui-error': {
      color: 'var(--input--color)',
    },
    '& input::placeholder': {
      color: 'neutral.500',
    },
  },
  error: {
    display: 'flex',
  },
}

export default styles
