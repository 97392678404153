const useTexts = () => {
  return {
    getHomeTitle: () => 'Inicio',

    getMediaProviderTitle: () => 'Medios',
    getIncomeTitle: () => 'Ingresos',
    getUsersTitle: () => 'Usuarios',

    getCardWeeklyTitle: () => 'De la semana',
    getCardLastWeekLabel: () => 'última semana',
    getCardMonthlyTitle: () => 'Del mes',
    getCardLastMonthLabel: () => 'último mes',
    getCardUniqueTitle: () => 'Únicos',
    getCardNewTitle: () => 'Nuevos',

    getChartUsersCount: () => 'Núm. de usuarios únicos',
    getChartPurchasesCount: () => 'Núm. de desbloqueos',

    getNoMediaEmptyState: () =>
      'Selecciona el medio del que quieres tener información',

    getPurchasesTitle: () => 'Noticias leídas a través de Payper',
    getPurchasesRowsPerPageLabel: () => 'Filas por página',
    getPurchasesDisplayedRowsLabel: (from, to, count) =>
      `${from} • ${to} de ${count}`,
    getPurchasesDisplayedRowsLongDescriptionLabel: (from, to, count) =>
      `${from} • ${to} de ${count} noticias disponibles`,
    getPurchasesTableLabel: () => 'Artículos comprados',
    getPurchasesPrevPageLabel: () => 'Ir a la página anterior',
    getPurchasesNextPageLabel: () => 'Ir a la página siguiente',
    getDownloadExcelAriaLabel: () => 'Descargar excel',

    getPublicDataFormNameLabel: () => 'Nombre público',
    getPublicDataFormLogoLabel: () => 'Url del logotipo',
    getPublicDataFormLogoRequirementsLabel: () =>
      'Para obtener la mejor legibilidad del logo recomendamos formatos PNG o SVG, sin fondo y  sugerimos logotipo horizontal o isotipo reducido.',
    getPublicDataFormPriceLabel: () => 'Precio del artículo',
    getPublicDataFormAccessTitleLabel: () =>
      'Título a usar durante el registro',
    getPublicDataFormAccessDescriptionLabel: () =>
      'Descripción a usar durante el registro',
    getPublicDataFormAccessRequirementsLabel: (maxLength) =>
      `Opcional. Debe cumplir una longitud máxima de ${maxLength} caracteres.`,
    getPublicDataFormSubmitAction: () => 'Actualizar mis datos públicos',

    getTokenPanelHeadingLabel: () => 'Dominio autorizado',
    getTokenPanelOutputLabel: () => 'Token generado',
    getTokenPanelGetTokenAction: () => 'Obtener token',
    getTokenPanelCopyTokenAction: () => 'Copiar token',

    getSecretPanelWarningLabel: () =>
      'Recuerda que la generación de un nuevo secreto invalidará el anterior',
    getSecretPanelWarningRegeneratedLabel: () =>
      'Guarda el secreto en un lugar seguro. Una vez que salgas de esta ventana, ya no estará visible.',
    getSecretPanelOutputLabel: () => 'Nuevo secreto',
    getSecretPanelGetSecretAction: () => 'Regenerar secreto',
    getSecretPanelCopySecretAction: () => 'Copiar secreto',

    getPasswordFormCurrentPasswordLabel: () => 'Contraseña actual',
    getPasswordFormNewPasswordLabel: () => 'Nueva contraseña',
    getPasswordFormRequirementsLabel: () =>
      'Tu contraseña debe ser de al menos 8 caracteres, con una mayúscula, una minúscula y un número',
    getPasswordFormTogglePasswordAction: () => 'Mostrar u ocultar contraseña',
    getPasswordFormUpdateAction: () => 'Actualizar mi contraseña',

    getConfigurationTitle: () => 'Configuración',
    getTabsAriaLabel: () => 'Menú de configuración',
    getMediaDataTitle: () => 'Datos del medio',
    getChangePasswordTitle: () => 'Cambiar contraseña',
    getGenerateTokenTitle: () => 'Obtener token para API',
    getRegenerateSecretTitle: () => 'Regenerar secreto',

    getNoPurchasesTitleLabel: () => 'Bienvenido a Payper',
    getNoPurchasesTextLabel: () =>
      'Aquí podrás ver el registro de las noticias desbloqueadas a través de Payper.',
    getNoFilteredPurchasesTitleLabel: () =>
      'No existen registros para los filtros aplicados',
    getNoFilteredPurchasesTextLabel: () =>
      'Cambia una de las fechas de filtrado para hacer una nueva búsqueda.',

    getUserLogoutAction: () => 'Cerrar sesión',

    getLogoutTitleLabel: () => '¿Quieres salir de Payper?',

    getConfirmAction: () => 'Sí',
    getCancelAction: () => 'No',

    getErrorRequired: () => 'Campo obligatorio',
    getErrorUrl: () => 'Debe introducir una url válida',
    getErrorPassword: () => 'Contraseña inválida',
    getInvalidDateError: () => 'Fecha inválida',
    getInvalidIntervalError: () =>
      'Intervalo inválido. Modifique una de las fechas límite.',
    getTitleMaxLengthExceededError: (maxLength) =>
      `El texto del título no puede exceder los ${maxLength} caracteres.`,
    getDescriptionMaxLengthExceededError: (maxLength) =>
      `El texto de la descripción no puede exceder los ${maxLength} caracteres.`,

    getUnexpectedError: () => 'Algo fue mal, inténtelo de nuevo más tarde',
    getSuccessMessage: () => 'Los cambios se han guardado correctamente',
    getOldPasswordError: () => 'Contraseña actual incorrecta',
  }
}

export default useTexts
