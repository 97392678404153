import { format } from 'date-fns'

import { formatPrice } from '../../../format'

const calculatePercentageChange = (previousValue, newValue) =>
  Math.round(((newValue - previousValue) / Math.abs(previousValue)) * 100)

export const formatPriceCardContent = (cardData) =>
  formatCardContent(cardData, formatPrice)

const formatCardContent = (cardData, valueFormatFn = (n) => n.toString()) => {
  const cardContent = {}
  cardContent.startDate = format(new Date(cardData.period.startDate), 'dd/MM')
  cardContent.endDate = format(new Date(cardData.period.endDate), 'dd/MM')
  cardContent.value = valueFormatFn(cardData.current)
  if (cardData.previous !== 0) {
    cardContent.balanceValue = calculatePercentageChange(
      cardData.previous,
      cardData.current,
    )
  }
  return cardContent
}

export default formatCardContent
