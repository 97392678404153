import { subMonths } from 'date-fns'

import { getAccessToken } from './authentication'
import { getMonthPeriodForDate, getSearchParamsFromPeriod } from './common'

const BACKOFFICE_ENDPOINT = process.env.REACT_APP_BACKOFFICE_ENDPOINT

const getUniqueUsersForPeriod = async (mediaProviderIds, period) => {
  const searchParams = getSearchParamsFromPeriod(period)
  for (const id of mediaProviderIds) {
    searchParams.append('mediaId', id)
  }

  const response = await fetch(
    `${BACKOFFICE_ENDPOINT}/media-provider-admin/unique-users?${searchParams.toString()}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  )

  if (response.ok) {
    return await response.json()
  } else {
    throw new Error(await response.json().then((r) => r.error))
  }
}

const getNewUsersForPeriod = async (mediaProviderIds, period) => {
  const searchParams = getSearchParamsFromPeriod(period)
  for (const id of mediaProviderIds) {
    searchParams.append('mediaId', id)
  }

  const response = await fetch(
    `${BACKOFFICE_ENDPOINT}/media-provider-admin/new-users?${searchParams.toString()}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  )

  if (response.ok) {
    return await response.json()
  } else {
    throw new Error(await response.json().then((r) => r.error))
  }
}

export const getMonthUniqueUsers = async (mediaProviderIds) => {
  const currentMonthPeriod = getMonthPeriodForDate(new Date())
  const currentMonthUsers = await getUniqueUsersForPeriod(
    mediaProviderIds,
    currentMonthPeriod,
  )

  const previousMonthPeriod = getMonthPeriodForDate(subMonths(new Date(), 1))
  const previousMonthUsers = await getUniqueUsersForPeriod(
    mediaProviderIds,
    previousMonthPeriod,
  )

  const users = {
    period: currentMonthPeriod,
    current: currentMonthUsers,
    previous: previousMonthUsers,
  }
  return users
}

export const getMonthNewUsers = async (mediaProviderIds) => {
  const currentMonthPeriod = getMonthPeriodForDate(new Date())
  const currentMonthUsers = await getNewUsersForPeriod(
    mediaProviderIds,
    currentMonthPeriod,
  )

  const previousMonthPeriod = getMonthPeriodForDate(subMonths(new Date(), 1))
  const previousMonthUsers = await getNewUsersForPeriod(
    mediaProviderIds,
    previousMonthPeriod,
  )

  const users = {
    period: currentMonthPeriod,
    current: currentMonthUsers,
    previous: previousMonthUsers,
  }
  return users
}
