import baseTheme from '../base'

const MuiFormHelperText = {
  defaultProps: {
    component: 'span',
  },
  styleOverrides: {
    root: {
      display: 'flex',
      alignItems: 'center',
      columnGap: baseTheme.spacing(0.625),
      marginTop: baseTheme.spacing(0.5),
      marginLeft: baseTheme.spacing(0.75),
      marginRight: baseTheme.spacing(0.75),
      fontWeight: 500,
      color: baseTheme.palette.neutral[400],

      '& > svg': {
        fontSize: baseTheme.spacing(1.25),
      },
    },
  },
}

export default MuiFormHelperText
