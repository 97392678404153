const DEFAULT_OPTIONS = {
  minimumFractionDigits: 2,
  signDisplay: 'never',
}

export const formatNumber = (figure, options) => {
  const format = (number) =>
    new Intl.NumberFormat('es-ES', { ...DEFAULT_OPTIONS, ...options }).format(
      number.toFixed(2),
    )

  return typeof figure === 'number'
    ? format(figure)
    : typeof figure === 'string'
    ? format(Number(figure))
    : figure
}

export const formatPrice = (number) =>
  new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(
    number,
  )

export const formatDateParam = (date) => {
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()
  return `${year}${month}${day}`
}
