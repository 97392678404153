import { startOfWeek, subWeeks, subMonths } from 'date-fns'

import { getAccessToken } from './authentication'
import { getMonthPeriodForDate, getSearchParamsFromPeriod } from './common'

const BACKOFFICE_ENDPOINT = process.env.REACT_APP_BACKOFFICE_ENDPOINT

const getIncomeForPeriod = async (mediaIds, period) => {
  const searchParams = getSearchParamsFromPeriod(period)
  for (const id of mediaIds) {
    searchParams.append('mediaId', id)
  }

  const response = await fetch(
    `${BACKOFFICE_ENDPOINT}/media-provider-admin/income?${searchParams.toString()}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  )
  if (response.ok) {
    return await response.json()
  } else {
    throw new Error(await response.json().then((r) => r.error))
  }
}

const getWeekPeriodForDate = (date) => ({
  startDate: startOfWeek(date, { weekStartsOn: 1 }),
  endDate: date,
})

const getComparativeIncome = async (
  mediaIds,
  currentDate,
  previousDate,
  getPeriodForDateFn,
) => {
  const currentPeriod = getPeriodForDateFn(currentDate)
  const currentIncome = await getIncomeForPeriod(mediaIds, currentPeriod)

  const previousPeriod = getPeriodForDateFn(previousDate)
  const previousIncome = await getIncomeForPeriod(mediaIds, previousPeriod)

  const income = {
    period: currentPeriod,
    current: currentIncome,
    previous: previousIncome,
  }
  return income
}

export const getWeeklyIncome = async (mediaIds) =>
  await getComparativeIncome(
    mediaIds,
    new Date(),
    subWeeks(new Date(), 1),
    getWeekPeriodForDate,
  )

export const getMonthlyIncome = async (mediaIds) =>
  await getComparativeIncome(
    mediaIds,
    new Date(),
    subMonths(new Date(), 1),
    getMonthPeriodForDate,
  )
