import baseTheme from '../base'

const MuiPaginationItem = {
  styleOverrides: {
    root: {
      borderRadius: baseTheme.spacing(0.125),
      fontSize: baseTheme.spacing(0.875),
      fontWeight: 400,
      color: baseTheme.palette.neutral[600],

      '&:hover, &:focus': {
        color: baseTheme.palette.neutral[700],
        backgroundColor: baseTheme.palette.neutral[200],
      },

      [baseTheme.breakpoints.up('md')]: {
        fontSize: baseTheme.spacing(0.875),
      },

      '&.Mui-selected': {
        color: baseTheme.palette.neutral[700],
        backgroundColor: baseTheme.palette.neutral[300],
      },
      '&.Mui-disabled': {
        color: baseTheme.palette.neutral[400],
        opacity: 1,
      },
    },
  },
}

export default MuiPaginationItem
