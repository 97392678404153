import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Line, Bar, Cell, ComposedChart, XAxis, YAxis, Tooltip } from 'recharts'

import palette from '../../../../theme/base/palette'
import typography from '../../../../theme/base/typography'
import { useTexts } from '../../../../texts'
import styles from './kpis.styles'

const formatDate = (date) => date.replace(/^(\d{4})-(\d{2})-(\d{2})$/, '$3/$2')
const formatFullDate = (date) =>
  date.replace(/^(\d{4})-(\d{2})-(\d{2})$/, '$3/$2/$1')

const KPIsChartView = ({ data, height = 200, minWidth = 700 }) => {
  const texts = useTexts()
  const NAMES_DICTIONARY = {
    usersCount: texts.getChartUsersCount(),
    purchasesCount: texts.getChartPurchasesCount(),
  }
  const containerRef = useRef()

  const [containerWidth, setContainerWidth] = useState(0)

  const isFullChartVisible = containerWidth >= minWidth
  const chartWidth = isFullChartVisible ? containerWidth : minWidth

  const barSize = chartWidth / data.length

  const bottomAxisHeight = 30
  const topAxisHeight = 30
  const yAxisLength = height
  const totalHeight = yAxisLength + bottomAxisHeight + topAxisHeight

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect
      setContainerWidth(width)
    })

    observer.observe(containerRef.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  const marginLeft = isFullChartVisible
    ? { position: 'relative', left: -20 }
    : {}

  return (
    <div
      style={{ ...styles.chart, ...marginLeft, height: totalHeight + 10 }}
      ref={containerRef}
    >
      <ComposedChart
        minWidth={minWidth}
        width={chartWidth}
        height={totalHeight}
        data={data}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        barSize={barSize}
      >
        <XAxis
          xAxisId={0}
          dataKey='day'
          tickLine={false}
          tickMargin={9}
          tick={{
            fontSize: 10,
            color: palette.info.main,
          }}
          tickSize={6}
          tickFormatter={formatDate}
        />
        <YAxis
          type='number'
          orientation='left'
          axisLine={true}
          tick={{
            fontSize: 10,
            color: palette.info.main,
          }}
          tickSize={6}
        />
        <Bar dataKey='purchasesCount'>
          {data.map((entry, index) => (
            <Cell fill={palette.primary.main} key={`cell-${index}`} />
          ))}
        </Bar>
        <Line
          dataKey='usersCount'
          stroke={palette.primary.darkest}
          dot={false}
          activeDot={false}
          strokeWidth={2}
          strokeDasharray='5 5'
        />
        <Tooltip
          labelFormatter={formatFullDate}
          formatter={(value, name) => [value, NAMES_DICTIONARY[name]]}
          labelStyle={typography.subtitle2}
          wrapperStyle={{ border: 'none' }}
          itemStyle={{ color: palette.neutral[500] }}
        />
      </ComposedChart>
    </div>
  )
}

KPIsChartView.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string,
      usersCount: PropTypes.number,
      purchasesCount: PropTypes.number,
    }),
  ).isRequired,
  minWidth: PropTypes.number,
  height: PropTypes.number,
}

export default KPIsChartView
