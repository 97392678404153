import { Box, Typography } from '@mui/material'

import { ReactComponent as ArrowTop } from './ArrowTop.svg'
import { ReactComponent as ArrowBottom } from './ArrowBottom.svg'
import styles from './card.styles'

const formatDate = (date) => date.replace(/(\d+)\/(\d+)/, '$2-$1')

const Card = ({
  title,
  value,
  balanceValue,
  balanceLabel,
  startDate,
  endDate,
}) => {
  return (
    <Box sx={styles.root}>
      <Typography component='h2' sx={styles.title}>
        {title}
      </Typography>
      <Box sx={styles.info}>
        <Typography sx={styles.amount}>
          {value.includes('€') ? (
            <>
              {value.slice(0, -2)} <span>€</span>
            </>
          ) : (
            value
          )}
        </Typography>
        {Boolean(balanceValue) ? (
          <Typography
            sx={{
              ...styles.balance,
              ...(balanceValue > 0 ? styles.positive : styles.negative),
            }}
          >
            <Box component='span' sx={styles.wrapIcon}>
              {balanceValue > 0 ? <ArrowTop /> : <ArrowBottom />}
            </Box>
            <strong>{balanceValue}%</strong>
            {balanceLabel}
          </Typography>
        ) : (
          <Typography sx={{ ...styles.balance, color: 'transparent' }}>
            <span> - </span>
          </Typography>
        )}
        <Box sx={styles.date}>
          <Box component='time' dateTime={formatDate(startDate)}>
            {startDate}
          </Box>
          -
          <Box component='time' dateTime={formatDate(endDate)}>
            {endDate}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Card
