import { Box, Typography } from '@mui/material'

import styles from './heading.styles'

const Heading = ({ Icon, text, withPaddingLeft = false }) => {
  return (
    <Typography
      component='h2'
      sx={{
        ...styles.root,
        ...(withPaddingLeft ? styles.withPaddingLeft : null),
      }}
    >
      <Box sx={styles.icon}>{Icon}</Box>
      <Typography component='span' variant='h5'>
        {text}
      </Typography>
    </Typography>
  )
}

export default Heading
