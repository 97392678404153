import { getIdToken, getAccessToken } from './authentication'

const BACKOFFICE_ENDPOINT = process.env.REACT_APP_BACKOFFICE_ENDPOINT

export const getUserIdentity = async () => {
  const response = await fetch(
    `${BACKOFFICE_ENDPOINT}/media-provider-admin/user-identity`,
    {
      method: 'GET',
      headers: {
        Identity: getIdToken(),
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  )

  if (response.ok) {
    return await response.json()
  } else {
    throw new Error(await response.json().then((r) => r.error))
  }
}

export const getMediaProvider = async (mediaProviderId) => {
  const response = await fetch(
    `${BACKOFFICE_ENDPOINT}/media-provider-admin/media-providers/${mediaProviderId}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  )

  if (response.ok) {
    return await response.json()
  } else {
    throw new Error(await response.json().then((r) => r.error))
  }
}

export const updateMediaProvider = async (
  mediaProviderId,
  mediaEditableData,
) => {
  const response = await fetch(
    `${BACKOFFICE_ENDPOINT}/media-provider-admin/media-configuration/${mediaProviderId}/editable-data`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(mediaEditableData),
    },
  )
  if (response.ok) {
    return await response.json()
  } else {
    throw new Error(await response.json().then((r) => r.error))
  }
}

export const generateToken = async (mediaProviderId) => {
  const response = await fetch(
    `${BACKOFFICE_ENDPOINT}/media-provider-admin/media-configuration/${mediaProviderId}/token`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  )

  if (response.ok) {
    return await response.json().then((r) => r.token)
  } else {
    throw new Error(await response.json().then((r) => r.error))
  }
}

export const regenerateSecret = async (mediaProviderId) => {
  const response = await fetch(
    `${BACKOFFICE_ENDPOINT}/media-provider-admin/media-configuration/${mediaProviderId}/secret`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  )

  if (response.ok) {
    return await response.json().then((r) => r.secret)
  } else {
    throw new Error(await response.json().then((r) => r.error))
  }
}
