import baseTheme from '../base'

const MuiButton = {
  defaultProps: {
    color: 'inherit',
    variant: 'contained',
  },
  styleOverrides: {
    root: {
      ...baseTheme.typography.body2,
      padding: baseTheme.spacing(1.25, 2),
      fontWeight: 500,
      textTransform: 'none',
      boxShadow: baseTheme.shadows[0],

      '& .MuiButton-startIcon': {
        marginRight: baseTheme.spacing(1),

        '& > *:nth-of-type(1)': {
          fontSize: baseTheme.spacing(1.5),
        },
      },
      '& .MuiButton-endIcon': {
        marginLeft: baseTheme.spacing(1),

        '& > *:nth-of-type(1)': {
          fontSize: baseTheme.spacing(1.5),
        },
      },

      [baseTheme.breakpoints.up('md')]: {
        padding: baseTheme.spacing(1.25, 2),
      },
    },
    sizeSmall: {
      padding: baseTheme.spacing(1, 1.5),

      [baseTheme.breakpoints.up('md')]: {
        padding: baseTheme.spacing(1, 1.5),
      },
      [baseTheme.breakpoints.up('lg')]: {
        padding: baseTheme.spacing(1.25, 1.5),
      },
    },
    text: {
      color: baseTheme.palette.neutral[700],

      '&:hover': {
        color: baseTheme.palette.neutral[600],
        backgroundColor: baseTheme.palette.neutral[200],
      },
      '&:active': {
        color: baseTheme.palette.neutral[500],
        backgroundColor: 'transparent',
      },

      '&.Mui-disabled': {
        color: baseTheme.palette.neutral[400],
      },

      '& .MuiLoadingButton-loadingIndicator': {
        color: baseTheme.palette.neutral[600],
      },
    },
    textSecondary: {
      color: baseTheme.palette.neutral[100],

      '&:hover': {
        color: baseTheme.palette.neutral[600],
        backgroundColor: baseTheme.palette.neutral[200],
      },
      '&:active': {
        color: baseTheme.palette.neutral[700],
        backgroundColor: baseTheme.palette.neutral[300],
      },

      '&.Mui-disabled': {
        color: baseTheme.palette.neutral[400],
      },

      '& .MuiLoadingButton-loadingIndicator': {
        color: baseTheme.palette.neutral[200],
      },
    },
    contained: {
      '&:hover': {
        boxShadow: baseTheme.shadows[0],
      },
    },
    containedInherit: {
      color: baseTheme.palette.neutral[500],
      backgroundColor: baseTheme.palette.neutral[300],

      '&:hover': {
        backgroundColor: baseTheme.palette.neutral[200],
      },
      '&:active': {
        color: baseTheme.palette.neutral[500],
        backgroundColor: baseTheme.palette.neutral[200],
      },

      '&.Mui-disabled': {
        color: baseTheme.palette.neutral[400],
        backgroundColor: baseTheme.palette.neutral[200],
      },

      '& .MuiLoadingButton-loadingIndicator': {
        color: baseTheme.palette.getContrastText(
          baseTheme.palette.neutral[200],
        ),
      },
    },
    containedPrimary: {
      color: baseTheme.palette.neutral[700],
      backgroundColor: baseTheme.palette.primary.main,

      '&:hover': {
        color: baseTheme.palette.neutral[600],
        backgroundColor: baseTheme.palette.primary.dark,
      },
      '&:active': {
        backgroundColor: baseTheme.palette.neutral[300],
      },

      '& .MuiLoadingButton-loadingIndicator': {
        color: baseTheme.palette.getContrastText(
          baseTheme.palette.neutral[300],
        ),
      },
    },
    containedSecondary: {
      color: baseTheme.palette.neutral[100],
      backgroundColor: baseTheme.palette.neutral[700],

      '&:hover': {
        backgroundColor: baseTheme.palette.neutral[600],
      },
      '&:active': {
        backgroundColor: baseTheme.palette.neutral[500],
      },

      '&.Mui-disabled': {
        color: baseTheme.palette.neutral[200],
        backgroundColor: baseTheme.palette.neutral[500],
      },

      '& .MuiLoadingButton-loadingIndicator': {
        color: baseTheme.palette.getContrastText(
          baseTheme.palette.neutral[500],
        ),
      },

      '& b': {
        display: 'inline-block',
        width: baseTheme.spacing(1),
        marginLeft: baseTheme.spacing(0.125),
        fontWeight: 'normal',
        color: baseTheme.palette.primary.main,
      },
      '&.MuiLoadingButton-loading b': {
        color: 'transparent',
      },
    },
    containedTertiary: {
      color: baseTheme.palette.neutral[600],
      backgroundColor: baseTheme.palette.neutral[100],
    },
    containedDefault: {
      fontWeight: 400,
      backgroundColor: baseTheme.palette.neutral[300],

      '& b': {
        fontWeight: 'inherit',
        color: baseTheme.palette.primary.darkest,
      },
    },
  },
}

export default MuiButton
