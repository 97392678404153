import { Box, Container } from '@mui/material'

import styles from './kpis.styles'

const KPIsView = ({ renderCardsSection, renderKPIsChart }) => {
  return (
    <Container sx={styles.root}>
      <Box sx={styles.wrapper}>{renderCardsSection()}</Box>
      {renderKPIsChart()}
    </Container>
  )
}

export default KPIsView
