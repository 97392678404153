import { Box, Typography } from '@mui/material'
import Picture from '../Picture'

import imageAvif from './mobile-check.avif'
import imageWebp from './mobile-check.webp'
import imagePng from './mobile-check.png'
import styles from './emptyState.styles'

const EmptyState = ({ title, subtitle, sx = {} }) => {
  return (
    <Box sx={{ ...styles.root, ...sx }}>
      <Box sx={styles.content}>
        <Box sx={styles.info}>
          <Typography variant='h4'>{title}</Typography>
          <Typography sx={styles.text}>{subtitle}</Typography>
        </Box>
        <Box sx={styles.wrapImage}>
          <Picture
            width={516}
            height={332}
            avif={imageAvif}
            webp={imageWebp}
            fallback={imagePng}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default EmptyState
