import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Tab, Tabs as MuiTabs } from '@mui/material'

import styles from './tabs.styles'

const TabPanelPropTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
}

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      sx={styles.tabPanel}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  )
}

TabPanel.propTypes = TabPanelPropTypes

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  }
}

const Tabs = ({ tabs, activeTab = 0, ariaLabel, ...rest }) => {
  const [value, setValue] = useState(activeTab)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={styles.root}>
      <Box sx={styles.wrapTabs}>
        <MuiTabs
          aria-label={ariaLabel}
          value={value}
          onChange={handleChange}
          {...rest}
        >
          {tabs.map((tab, index) => (
            <Tab key={tab.label} label={tab.label} {...a11yProps(index)} />
          ))}
        </MuiTabs>
      </Box>
      <Box sx={styles.wrapTabPanel}>
        {tabs.map((tab, index) => (
          <TabPanel key={tab.label} value={value} index={index}>
            {tab.content}
          </TabPanel>
        ))}
      </Box>
    </Box>
  )
}

export default Tabs
