import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { ReactComponent as CaretCircle } from './CaretCircle.svg'
import { ReactComponent as Logout } from './Logout.svg'
import { ReactComponent as Logo } from './logo-payper.svg'
import Confirm from './Confirm'
import Menu from './Menu'
import styles from './header.styles'

const HeaderViewPropTypes = {
  name: PropTypes.string,
  logout: PropTypes.func,
  goToConfig: PropTypes.func,
}

const HeaderView = ({ name, logout, goToConfig }) => {
  const theme = useTheme()
  const widerThanTablet = useMediaQuery(theme.breakpoints.up('md'))

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const [confirmLogoutOpen, setConfirmLogoutOpen] = useState(false)

  const handleClickUserMenu = (ev) => setAnchorEl(ev.currentTarget)
  const handleCloseUserMenu = () => setAnchorEl(null)
  const handleClickLogout = () => setConfirmLogoutOpen(true)
  const handleCloseLogout = () => setConfirmLogoutOpen(false)
  const handleConfirmLogout = () => logout()

  return (
    <>
      <Box component='header' sx={styles.root} id='dashboard-header'>
        <Box sx={styles.toolbar}>
          <Box sx={styles.wrapLogo}>
            <Button sx={styles.logo} to='/dashboard'>
              <Logo aria-label='Payper logo' />
            </Button>
          </Box>
          <Box sx={styles.actions}>
            <Button
              id='user-button'
              endIcon={<CaretCircle />}
              aria-label='user-button'
              aria-controls={open ? 'user-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              sx={{ ...styles.user, ...(open ? styles.isOpen : null) }}
              onClick={handleClickUserMenu}
            >
              {name}
            </Button>
            {widerThanTablet ? (
              <IconButton
                aria-label='logout-button'
                onClick={handleClickLogout}
                sx={styles.logout}
              >
                <Logout />
              </IconButton>
            ) : null}

            <Menu
              widerThanTablet={widerThanTablet}
              anchorEl={anchorEl}
              open={open}
              handleClose={handleCloseUserMenu}
              logout={handleClickLogout}
              goToConfig={goToConfig}
            />
          </Box>
        </Box>
      </Box>
      {confirmLogoutOpen ? (
        <Confirm onConfirm={handleConfirmLogout} onCancel={handleCloseLogout} />
      ) : null}
    </>
  )
}

HeaderView.propTypes = HeaderViewPropTypes

export default HeaderView
