import baseTheme from '../base'

const MuiTab = {
  styleOverrides: {
    root: {
      ...baseTheme.typography.body1,
      minHeight: baseTheme.spacing(2.5),
      paddingTop: baseTheme.spacing(0.25),
      paddingLeft: baseTheme.spacing(1.25),
      paddingRight: baseTheme.spacing(1.25),
      paddingBottom: baseTheme.spacing(0.25),
      textTransform: 'none',
      color: baseTheme.palette.neutral[500],

      [baseTheme.breakpoints.up('md')]: {
        paddingTop: baseTheme.spacing(1),
        paddingBottom: baseTheme.spacing(1),
      },

      '&:hover': {
        color: baseTheme.palette.neutral[600],
      },

      '&.Mui-selected': {
        fontWeight: 500,
        color: baseTheme.palette.neutral[700],
      },
    },
  },
}

export default MuiTab
