import { useState } from 'react'

const useMediaProviderMultiSelector = (mediaProvidersData) => {
  const defaultSelected = mediaProvidersData.map((m) => m.id)
  const [selected, setSelected] = useState(defaultSelected)

  const formattedMediaProviders = mediaProvidersData.map((media) => ({
    value: media.id,
    label: `${media.name} - ${media.articlePrice} €`,
  }))

  return {
    options: formattedMediaProviders,
    selected: selected,
    setSelected: setSelected,
    isDisabled: formattedMediaProviders.length < 2,
  }
}

export default useMediaProviderMultiSelector
