import { useState } from 'react'
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { useTexts } from '../../../texts'
import { Alert, FormSkinWrapper, HelperText, TextField } from '../index'
import { ReactComponent as Eye } from './Eye.svg'
import { ReactComponent as EyeClosed } from './EyeClosed.svg'
import { ReactComponent as LockSimple } from './LockSimple.svg'
import styles from './passwordForm.styles'

const PasswordForm = ({
  fieldErrors,
  isSubmitting,
  onSubmit,
  formError,
  showSuccess,
}) => {
  const texts = useTexts()
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)

  return (
    <form onSubmit={onSubmit} noValidate>
      <FormSkinWrapper skin='light' sx={styles.wrapFields}>
        <TextField
          id='oldPassword'
          name='oldPassword'
          autoComplete='current-password'
          label={texts.getPasswordFormCurrentPasswordLabel()}
          type={showOldPassword ? 'text' : 'password'}
          helperText={fieldErrors.oldPassword}
          error={Boolean(fieldErrors.oldPassword)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <LockSimple />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position='end'
                onClick={() =>
                  setShowOldPassword((showOldPassword) => !showOldPassword)
                }
              >
                <IconButton
                  aria-label={texts.getPasswordFormTogglePasswordAction()}
                >
                  {showOldPassword ? <Eye /> : <EyeClosed />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          id='newPassword'
          name='newPassword'
          label={texts.getPasswordFormNewPasswordLabel()}
          type={showNewPassword ? 'text' : 'password'}
          helperText={fieldErrors.newPassword}
          error={Boolean(fieldErrors.newPassword)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <LockSimple />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position='end'
                onClick={() =>
                  setShowNewPassword((showPassword) => !showPassword)
                }
              >
                <IconButton
                  aria-label={texts.getPasswordFormTogglePasswordAction()}
                >
                  {showNewPassword ? <Eye /> : <EyeClosed />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          required
        />

        <Box mt={-0.5}>
          <HelperText text={texts.getPasswordFormRequirementsLabel()} />
        </Box>

        {showSuccess && (
          <Alert variant='success' text={texts.getSuccessMessage()} />
        )}
        {Boolean(formError) && <Alert variant='error' text={formError} />}

        <Box mt={1}>
          <LoadingButton
            fullWidth
            color='secondary'
            type='submit'
            loading={isSubmitting}
            disabled={isSubmitting}
            loadingIndicator={<CircularProgress size={16} />}
          >
            {texts.getPasswordFormUpdateAction()}
          </LoadingButton>
        </Box>
      </FormSkinWrapper>
    </form>
  )
}

export default PasswordForm
