import PropTypes from 'prop-types'
import { Box, Input } from '@mui/material'

import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker'

import { HelperText } from '../index'
import { ReactComponent as Calendar } from './Calendar.svg'
import { ReactComponent as Warning } from './Warning.svg'
import styles from './dateRangePicker.styles'

const datePickerPropTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  minDate: PropTypes.object,
  date: PropTypes.string,
  setDate: PropTypes.func,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  handleChangeDate: PropTypes.func,
}

const DatePicker = ({
  id,
  name,
  placeholder = 'dd/mm/aaaa',
  minDate = new Date(2000, 0),
  component = 'div',
  date,
  setDate,
  error,
  errorMessage,
  handleChangeDate,

  ...rest
}) => {
  return (
    <Box component={component} sx={styles.wrapItem}>
      <Box
        sx={{
          ...styles.item,
          ...(error ? styles.itemError : null),
        }}
      >
        <MUIDatePicker
          closeOnSelect
          value={date}
          onChange={handleChangeDate}
          OpenPickerButtonProps={{
            disableRipple: true,
          }}
          components={{
            OpenPickerIcon: Calendar,
          }}
          error={error}
          // setting a default value of null, [null],... for uncontrolled comp is NOT WORKING (thus we're using the controlled one)
          // defaultValue={defaultValue}
          // onBlur IS NOT WORKING
          // onBlur={() => console.log('blurrr')}

          //minDate is not working :/
          // minDate={minDate}

          // this error message IS NOT WORKING
          // slotProps={{
          //   textField: {
          //     helperText: errorMessage,
          //   },
          // }}

          // this error handler IS NOT WORKING
          // onError={handleDefaultError}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <Box sx={styles.wrapControls}>
              <Box sx={styles.wrapButton}>{InputProps?.endAdornment}</Box>
              <Input
                {...inputProps}
                ref={inputRef}
                id={id ?? name}
                name={name}
                placeholder={placeholder}
                disableUnderline
                sx={styles.input}
                error={error}
                helpertext={errorMessage}
              />
            </Box>
          )}
          PaperProps={{
            sx: {
              boxShadow: 1,
              backgroundColor: 'neutral.100',

              '& .MuiPickersDay-root.Mui-selected': {
                backgroundColor: 'primary.main',
              },
            },
          }}
          {...rest}
        />
      </Box>
      {Boolean(errorMessage) ? (
        <HelperText variant='error' Icon={<Warning />} text={errorMessage} />
      ) : null}
    </Box>
  )
}

DatePicker.propTypes = datePickerPropTypes

export default DatePicker
