import baseTheme from '../base'

const MuiTabs = {
  defaultProps: {
    scrollButtons: false,
    variant: 'scrollable',
  },
  styleOverrides: {
    root: {
      minHeight: baseTheme.spacing(2.5),
    },
    indicator: {
      height: baseTheme.spacing(0.3125),
      borderTopLeftRadius: baseTheme.spacing(0.25),
      borderTopRightRadius: baseTheme.spacing(0.25),
    },
  },
}

export default MuiTabs
