import { useState, useEffect } from 'react'

import { getKPIsChartData } from '../../../../services'
import KPIsChartView from './KPIsChart'
import KPIsView from './View'
import IncomeCardGroup from './IncomeCardGroup'
import UsersCardGroup from './UsersCardGroup'

const KPIs = ({ mediaProviderIds }) => {
  const [kpisChartData, setKpisChartData] = useState([])

  useEffect(() => {
    ;(async () => {
      if (Boolean(mediaProviderIds)) {
        setKpisChartData(await getKPIsChartData(mediaProviderIds))
      }
    })()
  }, [mediaProviderIds])

  return (
    <KPIsView
      renderCardsSection={() => (
        <>
          <IncomeCardGroup mediaProviderIds={mediaProviderIds} />
          <UsersCardGroup mediaProviderIds={mediaProviderIds} />
        </>
      )}
      renderKPIsChart={() => <KPIsChartView data={kpisChartData} />}
    />
  )
}

export default KPIs
