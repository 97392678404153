import baseTheme from '../base'

const fontStyles = {
  fontSize: baseTheme.spacing(0.75),
  fontWeight: 400,
  lineHeight: 1.5,
  color: baseTheme.palette.neutral[500],

  [baseTheme.breakpoints.up('md')]: {
    fontSize: baseTheme.spacing(0.875),
  },
}

const MuiTablePagination = {
  defaultProps: {
    component: 'div',
  },
  styleOverrides: {
    root: {
      borderTop: '1px solid rgba(156, 156, 156, 0.2)',

      // NOTE: Increase specificity
      '&[class]': {
        paddingTop: baseTheme.spacing(0.25),
      },

      '& .MuiInputBase-root': {
        marginRight: baseTheme.spacing(0.5),

        [baseTheme.breakpoints.up('md')]: {
          marginRight: baseTheme.spacing(1),
        },
      },
    },
    toolbar: {
      paddingLeft: baseTheme.spacing(1),
      paddingRight: baseTheme.spacing(0.5),

      [baseTheme.breakpoints.up('sm')]: {
        paddingLeft: baseTheme.spacing(1),
        paddingRight: baseTheme.spacing(0.5),
      },
      [baseTheme.breakpoints.up('lg')]: {
        paddingLeft: baseTheme.spacing(1.5),
        paddingRight: baseTheme.spacing(0.5),
      },
    },
    spacer: {
      flex: 0,
    },
    selectLabel: {
      ...fontStyles,
      marginLeft: 'auto',
    },
    select: {
      padding: baseTheme.spacing(0.5, 2.5, 0.5, 1),
      borderRadius: baseTheme.spacing(0.5),
      textAlignLast: 'center',
      background: baseTheme.palette.neutral[200],

      // NOTE: Need to increase the specificity
      '&.MuiInputBase-input.MuiNativeSelect-select.MuiNativeSelect-standard': {
        paddingRight: baseTheme.spacing(2.5),

        '&:hover': {
          outline: `max(2px, 0.08em) solid ${baseTheme.palette.neutral[300]}`,
          boxShadow: baseTheme.shadows[3],
        },
        '&:focus': {
          borderRadius: baseTheme.spacing(0.5),
          background: baseTheme.palette.neutral[200],
        },
        '&:focus-visible': {
          outline: `max(2px, 0.08em) solid ${baseTheme.palette.primary.main}`,
        },
      },
    },
    selectIcon: {
      fontSize: baseTheme.spacing(1.5),
      color: baseTheme.palette.neutral[500],
      right: baseTheme.spacing(0.5),
    },
    displayedRows: {
      ...fontStyles,
      order: -1,
      marginRight: baseTheme.spacing(2),
    },
  },
}

export default MuiTablePagination
