const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: {
      xs: 1,
      md: 1.5,
    },
    width: (theme) => `min(100%, ${theme.spacing(90)})`,
    mx: 'auto',
    px: {
      md: 2,
      xl: 5,
      xxl: 9,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  controls: {
    display: 'flex',
    columnGap: 1,
    px: 1,
  },
  downloadExcelBtn: {
    alignSelf: 'flex-start',
    height: 42,
    width: 42,
  },
}

export default styles
