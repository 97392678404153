const styles = {
  multiSelectorContainer: {
    backgroundColor: 'neutral.200',
    '& .MuiContainer-root': {
      pt: 2,
      pb: { xs: 2, lg: 3 },
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 1,
    },
  },
  multiSelector: {
    width: 540,
    maxWidth: '100%',
  },
}

export default styles
